<template>
  <div class="dzs-transactions">
    <div class="row">
      <div class="col-md-12">
        <card>
          <h4 slot="header" class="card-title">
            Transações por Período <span v-if="isBackoffice">(<span v-if="username != null" style="font-weight: bolder"> @{{username}}</span> )</span>
          </h4>
          <div class="row">
            <div class="col-md-2">
              <div class="row" style="margin-left: 0px">
                <label>Data Início</label>
              </div>
              <div class="row" style="margin-left: 0px">
                <el-date-picker v-model="startDate" type="date" placeholder="Escolha uma data"
                                :picker-options="dateOptsStart" @input="validatePeriod"
                                format="dd/MM/yyyy">
                </el-date-picker>
              </div>
            </div>
            <div class="col-md-2">
              <div class="row" style="margin-left: 0px">
                <label>Data Fim</label>
              </div>
              <div class="row" style="margin-left: 0px">
                <el-date-picker v-model="endDate" type="date" placeholder="Escolha uma data"
                                :picker-options="dateOptsEnd"
                                format="dd/MM/yyyy">
                </el-date-picker>
              </div>
            </div>
            <div class="col-sm-2">
              <fg-input label="Exibir">
                <el-select
                  class="select-default"
                  v-model="pagination.perPage"
                  placeholder="Por Página">
                  <el-option
                    class="select-default"
                    v-for="item in [100, 500, 1000, 5000]"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </fg-input>
            </div>
            <div class="col-sm-2">
              <fg-input label="Tipo de Transação">
                <el-select
                  class="select-default"
                  v-model="status"
                  placeholder="Status">
                  <el-option
                    class="select-default"
                    v-for="item in tableStatus"
                    :key="item"
                    :label="statusTranslate(item)"
                    :value="item">
                  </el-option>
                </el-select>
              </fg-input>
            </div>
            <div class="col-md-2">
              <p-button @click.prevent="search" style="margin-top: 24px">
                <i class="fas fa-search-dollar"></i> BUSCAR
              </p-button>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label for="advancedFilter" class="advanced-filter d-flex align-items-center">
                <fg-input id="advancedFilter" type="checkbox" :checked="advancedFilterActive" @change="advancedFilterActive = !advancedFilterActive"></fg-input>
                Busca Avançada
              </label>
            </div>
          </div>
          <div class="row" v-show="advancedFilterActive">
            <div class="col-md-3">
              <fg-input label="Tipo de Pix">
                <el-select class="select-default" v-model="advancedFilterTransactionType" placeholder="Tipo do Pix">
                  <el-option class="select-default" label="Pix Entrada" value="PIX"></el-option>
                  <el-option class="select-default" label="Pix Saída" value="PIX_PAYMENT"></el-option>
                </el-select>
              </fg-input>
            </div>
            <div class="col-md-3">
              <fg-input label="Identificador">
                <el-select class="select-default" v-model="advancedFilterField" placeholder="Identificador">
                  <el-option class="select-default" label="ID da Transação" value="TRANSACTION_ID"></el-option>
                  <el-option class="select-default" label="CPF/CNPJ" value="TAX_ID" :disabled="advancedFilterTransactionType === 'PIX_PAYMENT'"></el-option>
                  <el-option class="select-default" label="Chave Pix" value="KEY" :disabled="advancedFilterTransactionType === 'PIX'"></el-option>
                </el-select>
              </fg-input>
            </div>
            <div class="col-md-3">
              <fg-input type="text" label="Valor do Filtro" v-model="advancedFilter"></fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3" v-show="this.timelineItems.length > 0">
              <p-button @click.prevent="generatePdf" style="margin-top: 24px">
                <i class="fa-regular fa-file-pdf"></i> Exportar PDF
              </p-button>
            </div>
            <div class="col-md-3" v-show="this.timelineItems.length > 0">
              <p-button @click.prevent="generateCsv" style="margin-top: 24px">
                <i class="fas fa-file-invoice"></i> Exportar CSV
              </p-button>
            </div>
            <div class="col-md-3" v-show="this.timelineItems.length > 0">
              <p-button @click.prevent="generateOfx" style="margin-top: 24px">
                <i class="fas fa-file-invoice"></i> Exportar OFX
              </p-button>
            </div>
          </div>
        </card>
      </div>
      <div class="col-md-12" v-if="timelineItems.length>0"
           style="margin: 0 auto">
        <card>
          <div class="card-body row">
            <div class="col-sm-12 mt-2">
              <el-table class="table-striped"
                        :data="timelineItems"
                        style="width: 100%">
                <el-table-column :min-width="130" label="Descrição">
                  <template slot-scope="item">
                    <span>
                      <span>
                        <i :class="item.row.in?'fas fa-arrow-down':'fas fa-arrow-up'" :style="item.row.in?'color:#00f000':'color:#f70000'"></i>
                        {{getTransactionName(item.row.transactionType, item.row.transactionSubType)}}
                      </span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="150" label="Beneficiário / Pagador">
                  <template slot-scope="item">
                    <span>
                      <span>
                        <p style="margin-bottom: 0"
                           v-if="['TED','TEV', 'BONUS', 'PIX_PAYMENT', 'BOLETO_PAYMENT', 'PAYMENT_PARTNER_PIX'].includes(item.row.transactionType) || (!item.row.in &&'BOLETO'==item.row.transactionType)">
                          <i class="far fa-user"></i> {{item.row.in?item.row.source:item.row.destination}}
                        </p>
                        <p style="margin-bottom: 0"
                           v-if="['PIX'].includes(item.row.transactionType)">
                          <i class="far fa-user"></i> {{item.row.destination}}
                        </p>
                        <p style="margin-bottom: 0"
                           v-if="['SPLIT_PIX'].includes(item.row.transactionType) && item.row.in">
                          <i class="far fa-user"></i> {{item.row.in?item.row.source:''}}
                        </p>
                        <p style="margin-bottom: 0"
                          v-if="['TED', 'PIX_PAYMENT', 'PIX'].includes(item.row.transactionType)">
                          <i class="fas fa-university" v-if="item.row.destinationBank!=null"></i> {{item.row.destinationBank}}
                        </p>
                        <p style="margin-bottom: 0"
                           v-if="['CHARGEBACK'].includes(item.row.transactionType)&&!item.row.in&&['PIX'].includes(item.row.transactionSubType)">
                          <i class="far fa-user"></i> {{item.row.destination}}
                        </p>
                      </span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="Data/Hora">
                  <template slot-scope="item">
                    <span>
                      <span>{{item.row.transactionDate}}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="90" label="Valor (R$)">
                  <template slot-scope="item">
                    <span>
                      <span><b>{{item.row.in?'+':'-'}} {{toMoney(item.row.fundsMovement)}}</b></span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="90" label="Saldo">
                  <template slot-scope="item">
                    <span>
                      <span>R$ {{toMoney(item.row.accountBalance)}}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="40" label="">
                  <template slot-scope="item">
                    <span>
                      <span>
                        <p-button v-if="['TED','TEV'].includes(item.row.transactionType)&&!item.row.in" @click="printReceipt(item.row.idTransaction)"
                                  class="btn btn-info" title="Ver Comprovante">
                          <i class="fas fa-file-invoice fa-2x" style="color: #0c072d;"/>
                        </p-button>
                        <p-button v-if="['PIX_PAYMENT'].includes(item.row.transactionType)&&!item.row.in" @click="printReceiptPix(item.row.idTransaction)"
                                  class="btn btn-info" title="Ver Comprovante">
                          <i class="fas fa-file-invoice fa-2x" style="color: #0c072d;"/>
                        </p-button>
                        <p-button v-if="['PIX'].includes(item.row.transactionType)&&item.row.in" @click="printReceiptPix(item.row.idTransaction)"
                                  class="btn btn-info" title="Ver Comprovante">
                          <i class="fas fa-file-invoice fa-2x" style="color: #0c072d;"/>
                        </p-button>
                        <p-button v-if="['CHARGEBACK'].includes(item.row.transactionType)&&!item.row.in&&['PIX'].includes(item.row.transactionSubType)" @click="printReceiptPix(item.row.idTransaction)"
                                  class="btn btn-info" title="Ver Comprovante">
                          <i class="fas fa-file-invoice fa-2x" style="color: #0c072d;"/>
                        </p-button>
                        <p-button v-if="['BOLETO_PAYMENT'].includes(item.row.transactionType)&&!item.row.in" @click="printReceiptPayment(item.row.idTransaction)"
                                  class="btn btn-info" title="Ver Comprovante">
                          <i class="fas fa-file-invoice fa-2x" style="color: #0c072d;"/>
                        </p-button>
                      </span>
                    </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="col-sm-6 pagination-success">
              <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE
                {{pagination.totalNumber}} REGISTROS.</p>
            </div>
            <div class="col-sm-6">
              <p-pagination class="pull-right"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="pagination.totalNumber"
                            :click="this.search">
              </p-pagination>
            </div>
          </div>
        </card>
<!--        <card>-->
<!--          <time-line>-->
<!--            <time-line-item v-for="item in timelineItems"-->
<!--                            v-bind:data="item"-->
<!--                            v-bind:key="item.pos"-->
<!--                            :class="item.in?'timeline-inverted':''"-->
<!--                            :badgeType="['FEE','SUBSCRIPTION','BOLETO'].includes(item.transactionType) ?-->
<!--                            'warning':(!item.in?-->
<!--                            'danger':(['CHARGEBACK'].includes(item.transactionType)?-->
<!--                            'info':'success'))"-->
<!--                            :badgeIcon="getTransactionLogo(item.transactionType)">-->
<!--              <badge slot="header" :type="['FEE','SUBSCRIPTION','BOLETO'].includes(item.transactionType) ?-->
<!--                            'warning':(!item.in?-->
<!--                            'danger':(['CHARGEBACK'].includes(item.transactionType)?-->
<!--                            'info':'success'))">-->
<!--                <b>{{getTransactionName(item.transactionType, item.transactionSubType)}}</b>-->
<!--                <p-button v-if="['TED','TEV'].includes(item.transactionType)&&!item.in" @click="printReceipt(item.idTransaction)"-->
<!--                          class="btn btn-info" title="Ver Comprovante">-->
<!--                  <i class="fas fa-file-invoice fa-3x" style="color: #ef8157;"/>-->
<!--                </p-button>-->
<!--                <p-button v-if="['PIX_PAYMENT'].includes(item.transactionType)&&!item.in" @click="printReceiptPix(item.idTransaction)"-->
<!--                          class="btn btn-info" title="Ver Comprovante">-->
<!--                  <i class="fas fa-file-invoice fa-3x" style="color: #ef8157;"/>-->
<!--                </p-button>-->
<!--                <p-button v-if="['PIX'].includes(item.transactionType)&&item.in" @click="printReceiptPix(item.idTransaction)"-->
<!--                          class="btn btn-info" title="Ver Comprovante">-->
<!--                  <i class="fas fa-file-invoice fa-3x" style="color: #ef8157;"/>-->
<!--                </p-button>-->
<!--                <p-button v-if="['BOLETO_PAYMENT'].includes(item.transactionType)&&!item.in" @click="printReceiptPayment(item.idTransaction)"-->
<!--                          class="btn btn-info" title="Ver Comprovante">-->
<!--                  <i class="fas fa-file-invoice fa-3x" style="color: #ef8157;"/>-->
<!--                </p-button>-->
<!--              </badge>-->
<!--              <div slot="content">-->
<!--                <i :class="item.in?'fas fa-arrow-down':'fas fa-arrow-up'"></i><b> R$ {{toMoney(item.fundsMovement)}}</b>-->
<!--                <p style="margin-bottom: 0"-->
<!--                   v-if="['TED','TEV', 'BONUS'].includes(item.transactionType) || (!item.in &&'BOLETO'==item.transactionType)">-->
<!--                  <i class="far fa-user"></i> {{item.in?item.source:item.destination}}-->
<!--                </p>-->
<!--                <p v-if="item.transactionType == 'TED'">-->
<!--                  <i class="fas fa-university" v-if="item.destinationBank!=null"></i> {{item.destinationBank}}-->
<!--                </p>-->
<!--              </div>-->
<!--              <h6 slot="footer">-->
<!--                <i class="fas fa-clock-o"></i> {{item.transactionDate}}-->
<!--              </h6>-->
<!--              <p slot="footer" style="font-size: 13px;padding-top: 3px;color: #6f6f6f;margin-bottom: -1px;">-->
<!--                Saldo: R$ {{toMoney(item.accountBalance)}}-->
<!--              </p>-->
<!--            </time-line-item>-->
<!--          </time-line>-->
<!--        </card>-->
      </div>
    </div>
  </div>
</template>
<script>
  import {DatePicker} from 'element-ui'
  import {Badge, TimeLine, TimeLineItem} from 'src/components/UIComponents'
  import {getTransactionName, getTransactionLogo} from 'src/components/Dashboard/Views/Transactions/timeline'
  import {callWs, failWs, postWs} from "src/ws.service"
  import {formatDate, daysBetween} from "src/util/date.utils"
  import {toMoney} from 'src/util/core.utils'
  import swal from 'sweetalert2'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import download from "downloadjs";
  import {isNullOrEmpty} from "../../../../util/core.utils";

  export default {
    components: {
      [DatePicker.name]: DatePicker,
      TimeLine,
      TimeLineItem,
      Badge,
      PPagination
    },
    data() {
      return {
        startDate: new Date(),
        endDate: new Date(),
        timelineItems: [],
        username: null,
        isBackoffice: false,
        status: null,
        tableStatus: [null, 'IN', 'OUT'],
        dateOptsStart: {
          disabledDate: this.disabledStartDate,
          shortcuts: [{
            text: 'Hoje',
            onClick(picker) {
              const date = new Date()
              picker.$emit('pick', date)
              this.endDate = date
            }
          },
            {
              text: 'Ontem',
              onClick(picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 24)
                picker.$emit('pick', date)
                this.endDate = date
              }
            },
            {
              text: 'Anteontem',
              onClick(picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 48)
                picker.$emit('pick', date)
                this.endDate = date
              }
            }]
        },
        advancedFilterActive: null,
        advancedFilterTransactionType: null,
        advancedFilterField: null,
        advancedFilter: null,
        dateOptsEnd: {
          disabledDate: this.disabledEndDate
        },
        pagination: {
          perPage: 100,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
        requestPdf: {
          username: null,
          listTr: [],
          startDate: '',
          endDate: '',
          typeTransaction: '',
        }
      }
    },
    created() {
      if(isNullOrEmpty(this.$route.params.username)) {
        this.isBackoffice = false
        this.username = localStorage.getItem("usernameDisplay")
      } else {
        this.isBackoffice = true
        this.username = this.$route.params.username
      }
    },
    watch: {
      advancedFilterActive: function (value) {
        if(!value) {
          this.advancedFilterTransactionType = null
          this.advancedFilterField = null
          this.advancedFilter = null
        }
      },
      advancedFilterTransactionType: function(value) {
        if((value === 'PIX_PAYMENT' && this.advancedFilterField === 'TAX_ID') || (value === 'PIX' && this.advancedFilterField === 'KEY')) {
          this.advancedFilterField = ''
        }
      }
    },
    methods: {
      toMoney,
      getTransactionName,
      getTransactionLogo,
      search() {

        if(daysBetween(this.startDate, this.endDate) > 31){
          swal({
            title: 'Aviso!',
            text: 'O período selecionado deve ser de no máximo 31 dias!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        } else if(this.advancedFilterActive && !this.advancedFilterTransactionType) {
          swal({
            title: 'Aviso!',
            text: 'Selecione um tipo de Pix para prosseguir!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        } else if(this.advancedFilterActive && !this.advancedFilterField) {
          swal({
            title: 'Aviso!',
            text: 'Selecione um identificador para prosseguir!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        } else if(this.advancedFilterActive && !this.advancedFilter) {
          swal({
            title: 'Aviso!',
            text: 'Informe um valor para o filtro para prosseguir!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        } else {
          callWs("/transaction/transactions-by-period",
            "POST", null, true, null,
            {
              typeTransaction: this.status,
              pageNumber: this.pagination.currentPage - 1,
              pageSize: this.pagination.perPage,
              startDate: formatDate(this.startDate, "DD/MM/YYYY"),
              endDate: formatDate(this.endDate, "DD/MM/YYYY"),
              username: this.username,
              advancedFilterTransactionType: this.advancedFilterTransactionType,
              advancedFilterField: this.advancedFilterField,
              advancedFilter: this.advancedFilter
            },
            this.loadTimeline,
            this.failSearch)
        }
      },
      failSearch() {
        swal({
          title: 'Não encontrado',
          text: 'Certifique-se de que os filtros estão preenchidos corretamente.',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      },
      loadTimeline(response) {
        this.timelineItems = response.data.list
        this.requestPdf.username = this.username
        this.requestPdf.listTr = response.data.list
        this.requestPdf.typeTransaction = this.status
        this.requestPdf.startDate = formatDate(this.startDate, "DD/MM/YYYY")
        this.requestPdf.endDate = formatDate(this.endDate, "DD/MM/YYYY")
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.timelineItems.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
        if (this.timelineItems.length <= 0) {
          swal({
            title: 'Não encontrado',
            text: 'No período selecionado não existem transações.',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      validatePeriod() {
        this.endDate = this.startDate
      },
      disabledStartDate(date) {
        let now = new Date()
        return date > now
      },
      disabledEndDate(date) {
        let now = new Date()
        return date > now || date < this.startDate
      },
      printReceipt(id) {
        postWs("/receipt/get-receipt-transactions",
          true,
          {idTransaction: id}, null,
          this.successPrintReceipt,
          (error) => {
            if (error.response.status == 404) {
              swal({
                title: 'Aviso!',
                text: 'Transação não encontrada!',
                buttonsStyling: true,
                timer: 5000,
                confirmButtonClass: 'btn btn-warning btn-fill',
                type: 'warning'
              })
              return;
            }
            swal({
              title: 'Aviso!',
              text: 'Erro ao gerar o comprovante, tente novamente!',
              buttonsStyling: true,
              timer: 5000,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            return
          })
      },
      printReceiptPix(id) {
        postWs("/pix/get-receipt-pix",
          true,
          {idTransaction: id}, null,
          this.successPrintReceipt,
          (error) => {
            if (error.response.status == 404) {
              swal({
                title: 'Aviso!',
                text: 'Transação não encontrada!',
                buttonsStyling: true,
                timer: 5000,
                confirmButtonClass: 'btn btn-warning btn-fill',
                type: 'warning'
              })
              return;
            }
            swal({
              title: 'Aviso!',
              text: 'Erro ao gerar o comprovante, tente novamente!',
              buttonsStyling: true,
              timer: 5000,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            return
          })
      },
      printReceiptPayment(id) {
        postWs("/boleto/pdf-boleto-payment",
          true,
          {id: id}, null,
          this.successPrintReceiptPayment,
          (error) => {
            if (error.response.status == 404) {
              swal({
                title: 'Aviso!',
                text: 'Transação não encontrada!',
                buttonsStyling: true,
                timer: 5000,
                confirmButtonClass: 'btn btn-warning btn-fill',
                type: 'warning'
              })
              return;
            }
            swal({
              title: 'Aviso!',
              text: 'Erro ao gerar o comprovante, tente novamente!',
              buttonsStyling: true,
              timer: 5000,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            return
          })
      },
      successPrintReceipt(response) {
        try {
          let pdfWindow = window.open("about:blank");
          pdfWindow.document.write("<html<head><title>SuitPay - Comprovante</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>");
          pdfWindow.document.write("<body><embed width='100%' height='100%' src='data:application/pdf;base64," + encodeURI(response.data.pdfBase64) + "'></embed></body></html>");
        } catch (ex) {
          swal({
            title: 'Aviso!',
            text: "Para a exibição do comprovante, favor permitir janela pop-up no navegador. Após permitido favor tentar novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      successPrintReceiptPayment(response) {
        try {
          let pdfWindow = window.open("about:blank");
          pdfWindow.document.write("<html<head><title>SuitPay - Comprovante</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>");
          pdfWindow.document.write("<body><embed width='100%' height='100%' src='data:application/pdf;base64," + encodeURI(response.data) + "'></embed></body></html>");
        } catch (ex) {
          swal({
            title: 'Aviso!',
            text: "Para a exibição do comprovante, favor permitir janela pop-up no navegador. Após permitido favor tentar novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      generatePdf() {
        if(daysBetween(this.startDate, this.endDate) > 31){
          swal({
            title: 'Aviso!',
            text: 'O período selecionado deve ser de no máximo 31 dias!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        callWs("/transaction/statement-transactions-pdf",
          "POST", null, true, null,
          {
            typeTransaction: this.status,
            pageNumber: this.pagination.currentPage - 1,
            pageSize: this.pagination.perPage,
            startDate: formatDate(this.startDate, "DD/MM/YYYY"),
            endDate: formatDate(this.endDate, "DD/MM/YYYY"),
            username: this.username,
            advancedFilterTransactionType: this.advancedFilterTransactionType,
            advancedFilterField: this.advancedFilterField,
            advancedFilter: this.advancedFilter
          },
          this.successGeneratePdf,
          (error) => {
            swal({
              title: 'Aviso!',
              text: 'Erro ao gerar o PDF do extrato, tente novamente!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            return
          })
      },
      successGeneratePdf(response) {
        try {
          let pdfWindow = window.open("about:blank");
          pdfWindow.document.write("<html<head><title>SuitPay - Extrato</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>");
          pdfWindow.document.write("<body><embed width='100%' height='100%' src='data:application/pdf;base64," + encodeURI(response.data) + "'></embed></body></html>");
        } catch (ex) {
          swal({
            title: 'Aviso!',
            text: "Para a exibição do extrato, favor permitir janela pop-up no navegador. Após permitido favor tentar novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      statusTranslate(status){
        let retorno = ''
        switch (status) {
          case 'IN':
            retorno = 'Entrada';
            break;
          case 'OUT':
            retorno = 'Saída';
            break;
          default:
            retorno = 'Todas';
        }
        return retorno
      },
      generateCsv() {
        if(daysBetween(this.startDate, this.endDate) > 31){
          swal({
            title: 'Aviso!',
            text: 'O período selecionado deve ser de no máximo 31 dias!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        callWs("/transaction/transactions-by-period-csv",
          "POST", null, true, null,
          {
            typeTransaction: this.status,
            pageNumber: this.pagination.currentPage - 1,
            pageSize: this.pagination.perPage,
            startDate: formatDate(this.startDate, "DD/MM/YYYY"),
            endDate: formatDate(this.endDate, "DD/MM/YYYY"),
            username: this.username,
            advancedFilterTransactionType: this.advancedFilterTransactionType,
            advancedFilterField: this.advancedFilterField,
            advancedFilter: this.advancedFilter
          },
          this.successGenerateCsv,
          this.failGenerateCsv
        )
      },
      successGenerateCsv(response) {
        try {
          download("data:application/csv;base64,"+response.data, "extrato_suitpay_"+formatDate(this.startDate, 'DD/MM/YYYY')+"_a_"+formatDate(this.endDate, 'DD/MM/YYYY')+".csv", "text/csv")
        } catch (ex) {
          swal({
            title: 'Erro!',
            text: "Erro ao gerar o extrato em CSV, favor tentar novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      failGenerateCsv() {
        swal({
          title: 'Aviso!',
          text: 'Erro ao gerar o CSV do extrato, tente novamente!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
        return
      },
      generateExcel() {
        if(daysBetween(this.startDate, this.endDate) > 31){
          swal({
            title: 'Aviso!',
            text: 'O período selecionado deve ser de no máximo 31 dias!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        callWs("/transaction/transactions-by-period-excel",
          "POST", null, true, null,
          {
            typeTransaction: this.status,
            pageNumber: this.pagination.currentPage - 1,
            pageSize: this.pagination.perPage,
            startDate: formatDate(this.startDate, "DD/MM/YYYY"),
            endDate: formatDate(this.endDate, "DD/MM/YYYY"),
            username: this.username,
            advancedFilterTransactionType: this.advancedFilterTransactionType,
            advancedFilterField: this.advancedFilterField,
            advancedFilter: this.advancedFilter
          },
          this.successGenerateExcel,
          (error) => {
            swal({
              title: 'Aviso!',
              text: 'Erro ao gerar o Excel do extrato, tente novamente!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            return
          })
      },
      successGenerateExcel(response) {
        try {
          download("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"+response.data,
            "extrato_suitpay"+"_"+this.username+"_"+formatDate(this.startDate, "DD/MM/YYYY")+"_a_"+formatDate(this.endDate, "DD/MM/YYYY")+".xlsx", "vnd.openxmlformats-officedocument.spreadsheetml.sheet")
        } catch (ex) {
          swal({
            title: 'Erro!',
            text: "Erro ao gerar o extrato em Excel, favor tentar novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      generateOfx() {
        if(daysBetween(this.startDate, this.endDate) > 31){
          swal({
            title: 'Aviso!',
            text: 'O período selecionado deve ser de no máximo 31 dias!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        callWs("/transaction/transactions-by-period-ofx",
          "POST", null, true, null,
          {
            typeTransaction: this.status,
            pageNumber: this.pagination.currentPage - 1,
            pageSize: this.pagination.perPage,
            startDate: formatDate(this.startDate, "DD/MM/YYYY"),
            endDate: formatDate(this.endDate, "DD/MM/YYYY"),
            username: this.username,
            advancedFilterTransactionType: this.advancedFilterTransactionType,
            advancedFilterField: this.advancedFilterField,
            advancedFilter: this.advancedFilter
          },
          this.successGenerateOfx,
          (error) => {
            swal({
              title: 'Aviso!',
              text: 'Erro ao gerar o OFX do extrato, tente novamente!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            return
          })
      },
      successGenerateOfx(response) {
        try {
          download("data:application/x-ofx;base64,"+response.data,
            "extrato_suitpay"+"_"+this.username+"_"+formatDate(this.startDate, "DD/MM/YYYY")+"_a_"+formatDate(this.endDate, "DD/MM/YYYY")+".ofx", "application/x-ofx")
        } catch (ex) {
          swal({
            title: 'Erro!',
            text: "Erro ao gerar o extrato OFX, favor tentar novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
    }
  }
</script>
<style lang="scss">
  .dzs-transactions {
    .btn.btn-info.btn-default {
      float: right;
      background: none !important;
      margin: 0 !important;
      padding: 0 !important;
    }
    .btn.btn-default{
      background-color: #17a2b8 !important;
    }
    .btn.btn-default:hover{
      background-color: #0a7282 !important;
    }
    .advanced-filter {
      gap: .5rem;
    }
    .advanced-filter div {
      margin: 0 !important;
    }
  }
</style>
