<template>
  <div class="row dzs-profile">
    <div class="col-lg-4 col-md-5" v-show="showOptions || showProfile || showWaitingApproval">
      <div class="card card-user">
        <div class="image">
          <img src="/static/img/background/bg-suitpay2.jpg" alt="...">
        </div>
        <div class="card-body">
          <div class="author">
            <a href="#" class="fileContainer">
              <img class="avatar border-gray" :src="loadDocSrc()" v-if="this.showAvatar"
                   style="background: #2c2c2c !important; border: 0px solid #2c2c2c !important" alt="...">
              <img class="avatar border-gray" src="/static/img/faces/user.png" v-else
                   style="background: #2c2c2c !important; border: 20px solid #2c2c2c !important" alt="...">
              <h5 class="title" style="color: #000000">{{this.account.name}}</h5>
              <input type="file" id="file" ref="file" accept=".png, .jpg, .jpeg" v-on:change="handleFileUpload()"/>
            </a>
            <p class="description" style="color: #667eea">
              {{this.usernameDisplay}}
            </p>
          </div>
          <p class="description text-center" style="color: #12a500" v-show="this.account.accountDocumentStatus == 'APPROVED'" v-html="this.statusAccount"/>
          <p class="description text-center" style="color: #ff0005" v-show="this.account.accountDocumentStatus != 'APPROVED'" v-html="this.statusAccount"/>
          <div class="description text-center" v-if=buttonCreateSubaccountDisabled>
            <button @click=openCreateDerivedAccount class="btn btn-info btn-fill btn-wd">
              Criar Subconta
            </button>
          </div>
        </div>
      </div>

      <upload-doc :key="uploadDocKey" v-show="showEditDocuments">
      </upload-doc>

      <reset-password>
      </reset-password>
    </div>

    <div class="col-lg-12 col-md-7" v-show="showCreateDerivedAccount">
      <derived-account :derivedAccount="derivedAccount">
      </derived-account>
    </div>

    <div class="col-lg-12 col-md-7" v-show="showEditDocuments">
      <upload-doc :key="uploadDocKey">
      </upload-doc>
    </div>

    <div class="col-lg-8 col-md-7" v-show="showProfile">
      <div class="card">
        <div class="card-header">
          <h4 class="title" style="margin-top: 0px">Dados da Conta</h4>
        </div>
        <div class="col-lg-12" style="margin-bottom: 30px">
          <p>Usuário: {{usernameDisplay}}</p>
          <p>Nome: {{account.name}}</p>
          <p v-show="account.legalPerson.companyName != null">Razão Social: {{account.legalPerson.companyName}}</p>
          <p v-show="isNaturalPerson(account.personType)">CPF: {{account.socialNumber}}</p>
          <p v-show="!isNaturalPerson(account.personType)">CNPJ: {{account.socialNumber}}</p>
          <p>E-mail: {{email}}</p>
          <p>Celular: {{account.phoneNumber1}}</p>
          <p v-show="account.phoneNumber2!=null">Telefone: {{account.phoneNumber2}}</p>
          <h4>Endereço</h4>
          <p>CEP: {{account.address.zipCode}}</p>
          <p>Rua/Av.: {{account.address.street}}</p>
          <p v-show="account.address.number!=null">Número: {{account.address.number}}</p>
          <p v-show="account.address.complement!=null">Complemento: {{account.address.complement}}</p>
          <p>Bairro: {{account.address.neighborhood}}</p>
          <p>Cidade: {{account.address.city}} - {{account.address.state}}</p>
          <h4>Contrato, Políticas e Termos de Uso</h4>
          <p v-show="isNaturalPerson(account.personType)"><a href="https://web.suitpay.app/static/pdf/CONTRATO_PESSOA_FISICA.pdf" target="_blank">Contrato Pessoa Física</a></p>
          <p v-show="!isNaturalPerson(account.personType)"><a href="https://web.suitpay.app/static/pdf/CONTRATO_PESSOA_JURIDICA.pdf" target="_blank">Contrato Pessoa Jurídica</a></p>
          <p><a href="https://web.suitpay.app/static/pdf/POLITICA_DE_PREVENCAO_A_LAVAGEM_DE_DINHEIRO_E_AO_FINANCIAMENTO_AO_TERRORISMO.pdf" target="_blank">Política de Prevenção a Lavagem de Dinheiro e ao Financiamento do Terrorismo</a></p>
          <p><a href="https://web.suitpay.app/static/pdf/POLITICA_DE_PRIVACIDADE_E_PROTECAO_DE_DADOS.pdf" target="_blank">Política de Privacidade e Proteção de Dados</a></p>
          <p><a href="https://web.suitpay.app/static/pdf/TERMOS_E_CONDICOES.pdf" target="_blank">Termos e Condições</a></p>
        </div>
      </div>
    </div>

    <div class="col-lg-8 col-md-7" v-show="!buttonProfileDisabled">
      <div class="card">
        <div class="card-header">
          <h4 class="title" style="margin-top: 0px">1º Passo - Configurar Conta</h4>
        </div>
        <div class="col-lg-12" style="margin-bottom: 30px">
          <p style="font-size: 18px">Para você usufruir de todas as funcionalidades e benefícios da sua conta
            <span style="color: black"><b style="color: #000000 !important;">SuitPay</b></span>,
            é necessário você seguir alguns passos.</p>
          <p style="font-size: 18px">Primeiro você deve preencher um formulário com os seus dados clicando no botão abaixo.</p>
          <p style="font-size: 18px">Vamos lá!</p>
          <div class="row">
            <div class="text-center col-lg-12">
              <button @click="openProfileEdit" class="btn btn-info btn-fill btn-wd">
                Configurar Conta
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-8 col-md-7" v-show="!buttonDocumentDisabled">
      <div class="card">
        <div class="card-header">
          <h4 class="title" style="margin-top: 0px">2º Passo - Enviar Documentos</h4>
        </div>
        <div class="col-lg-12" style="margin-bottom: 30px">
          <p style="font-size: 18px">Agora que você preencheu o formulário com os seus dados, nós precisamos que você nos envie a sua documentação
            para que seja validada pela nossa equipe.</p>
          <p style="font-size: 18px">Clique no botão abaixo e envie a documentação solicitada.</p>
          <div class="row">
            <div class="text-center col-lg-12">
              <button @click="$router.push('/user/profile/uploaddoc')" class="btn btn-info btn-fill btn-wd">
                Enviar Documentos
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-8 col-md-7" v-if="!buttonKycValidationDisabled">
      <div class="card">
        <div class="card-header">
          <h4 class="title" style="margin-top: 0px">3º Passo - Reconhecimento Facial</h4>
        </div>
        <div class="col-lg-12" style="margin-bottom: 30px">
          <p style="font-size: 18px">Agora que você enviou os documentos, nós precisamos validar sua identidade com a Biometria Facial.</p>
          <p style="font-size: 18px">Clique no botão abaixo ou acesse o QRcode no seu smartphone para iniciar a verificação.</p>
          <div class="row">
            <div class="text-center col-lg-12">
              <vue-qrcode
                :value="onboardingId"
              />
            </div>
          </div>
          <div class="row">
            <div class="text-center col-lg-12">
              <a :href="onboardingId" target="_blank" class="btn btn-info btn-fill btn-wd">
                Iniciar verificação
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-8 col-md-7" v-show="showWaitingApproval">
      <div class="card">
        <div class="card-header">
          <h4 class="title" style="margin-top: 0px">Aguardar Aprovação</h4>
        </div>
        <div class="col-lg-12" style="margin-bottom: 30px">
          <p>Você preencheu todos os dados e enviou todos os documentos necessários para aprovação da sua conta.</p>
          <p>Nossa equipe está analisando o seu cadastro e em até 48h iremos responder a sua solicitação de aprovação.</p>
          <p>Se todos os seus dados e documentos estiverem de acordo com as nossas políticas, você será liberado para aproveitar
            todas as nossas vantagens.</p>
          <p>Você pode acompanhar o andamento da sua solicitação nessa mesma tela.</p>
          <p>Muito obrigado por fazer parte da <span style="color: black"><b style="color: #000000 !important;">SuitPay</b></span>.</p>
        </div>
      </div>
    </div>

    <div class="col-lg-12 col-md-7" v-show="showEditProfile">
      <div class="card">
        <div class="card-header">
          <h4 class="title">Configurar Conta</h4>
        </div>
        <div class="card-body">
          <form @submit.prevent="saveAccount">
            <div class="row">
              <div class="col-md-5">
                <fg-input type="text"
                          label="Usuário"
                          disabled="true"
                          name="usuario"
                          v-model="usernameDisplay"
                          key="username-display">
                </fg-input>
              </div>
              <div class="col-md-7">
                <fg-input type="email"
                          label="E-mail"
                          disabled="true"
                          name="email"
                          v-model="email"
                          key="email">
                </fg-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <fg-input type="tel"
                          label="Celular"
                          v-mask="mask(account.phoneNumber1)"
                          name="Celular"
                          v-validate="isLocalPhoneNumber(account.phoneNumber1) ? {required: true, min: 14} : {}"
                          :error="getError('Celular')"
                          v-model="account.phoneNumber1"
                          :disabled="formDisabled"
                          key="celular">
                </fg-input>
              </div>
              <div class="col-md-3">
                <fg-input type="tel"
                          label="Telefone"
                          name="Telefone"
                          v-mask="mask(account.phoneNumber2)"
                          v-model="account.phoneNumber2"
                          :disabled="formDisabled"
                          key="telefone">
                </fg-input>
              </div>
            </div>
            <div class="row" style="margin-top: 30px">
              <div class="col-md-5">
                <fg-input label="Tipo de Pessoa" name="tipo pessoa" key="tipo-pessoa">
                  <el-select size="large"
                             placeholder="Selecionar"
                             v-on:change="changePersonType"
                             v-model="account.personType"
                             :disabled="formDisabled">
                    <el-option class="select-success"
                               value="NATURAL_PERSON"
                               label="Pessoa Física"/>
                    <el-option class="select-info"
                               value="NATURAL_PERSON_E"
                               label="Pessoa Fisica Estrangeira"/>
                    <el-option class="select-info"
                               value="LEGAL_PERSON"
                               label="Pessoa Jurídica"/>
                  </el-select>
                </fg-input>
              </div>
              <div class="col-md-7">
                <fg-input :label="isNaturalPerson(account.personType) ? 'CPF':'CNPJ'"
                          type="tel"
                          v-mask="isNaturalPerson(account.personType) ? ['###.###.###-##']:['##.###.###/####-##']"
                          name="CPF/CNPJ"
                          v-validate="{cpfCnpjValid:true, required: true, min: 14}"
                          :error="getError('CPF/CNPJ')"
                          v-model="account.socialNumber"
                          :disabled="formDisabled"
                          key="social-number">
                </fg-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <fg-input type="text"
                          :label="isNaturalPerson(account.personType) ?'Nome Completo':'Nome Completo do Representante'"
                          maxlength="70"
                          name="Nome Completo"
                          v-validate="{required: true, regex: /^([A-Za-zÀ-ÖØ-öø-ÿ]+(?:\s+[A-Za-zÀ-ÖØ-öø-ÿ]+)+)$/}"
                          :error="getError('Nome Completo')"
                          v-model="account.name"
                          :disabled="formDisabled"
                          key="nome-completo">
                </fg-input>
              </div>
            </div>

            <div class="row" v-if="isLegalPerson(account.personType)">
              <div class="col-md-3">
                <p-checkbox class="text-left" v-model="isForeigner" key="terms">
                  Representante Estrangeiro
                </p-checkbox>
              </div>
            </div>

            <div class="row" v-if="isLegalPerson(account.personType)">
              <div class="col-md-3" v-if="isForeigner">
                <fg-input label="Passaporte do Representante"
                          type="tel"
                          maxlength="9"
                          name="Passaporte do Representante"
                          :error="getError('Passaporte do Representante')"
                          v-model="account.legalPerson.procuratorSocialNumber"
                          :disabled="formDisabled"
                          key="passaporte-do-representante">
                </fg-input>
              </div>
              <div class="col-md-3" v-if="!isForeigner">
                <fg-input label="CPF do Representante"
                          type="tel"
                          v-mask="['###.###.###-##']"
                          name="CPF do Representante"
                          v-validate="{cpfCnpjValid:true, required: true, min: 14}"
                          :error="getError('CPF do Representante')"
                          v-model="account.legalPerson.procuratorSocialNumber"
                          :disabled="formDisabled"
                          key="cpf-do-representante">
                </fg-input>
              </div>
              <div class="col-md-3">
                <fg-input label="Data de Nascimento do Representante"
                          type="tel"
                          v-mask="['##/##/####']"
                          name="Data de Nascimento do Representante"
                          v-validate="{dateBeforeValid:true, required: true, min:10}"
                          :error="getError('Data de Nascimento do Representante')"
                          v-model="account.legalPerson.procuratorBirthday"
                          :disabled="formDisabled"
                          key="dn-representante">
                </fg-input>
              </div>
              <div class="col-md-3" v-if="isForeigner">
                <label class="control-label">Naturalidade do Representante</label>
                <autocomplete ref="naturalidade_representante"
                              name="Naturalidade_Representante"
                              placeholder="Selecionar"
                              v-validate="{required: true}"
                              key="naturalidade"
                              :error="getError('Naturalidade Representante')"
                              :source="this.countriesNameAsc"
                              input-class="form-control"
                              results-value="code"
                              :results-display="displayCountryName"
                              @selected="selectNaturalidade">
                </autocomplete>
              </div>
            </div>
            <div v-if="isLegalPerson(account.personType)">
              <div class="row">
                <div class="col-md-12">
                  <fg-input type="text"
                            label="Razão Social"
                            maxlength="100"
                            name="Razão Social"
                            v-validate="{required: true}"
                            :error="getError('Razão Social')"
                            v-model="account.legalPerson.companyName"
                            :disabled="formDisabled"
                            key="razao-social">
                  </fg-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <div class="row" style="padding-left: 15px">
                    <fg-input type="tel"
                              v-mask="['##/##/####']"
                              label="Data de Constituição"
                              name="Data de Constituição"
                              v-validate="{dateBeforeValid:true, required: true, min:10}"
                              v-model="account.legalPerson.openingDate"
                              :error="getError('Data de Constituição')"
                              :disabled="formDisabled"
                              key="data-constituicao">
                    </fg-input>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-header">
                      <span class="title">Quadro Societário</span>
                    </div>
                    <div class="card-body">
                      <div class="row" v-if="!formDisabled">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-2">
                              <fg-input label="Tipo de Pessoa" name="tipo pessoa" key="tipo-pessoa">
                                <el-select size="large"
                                           placeholder="Selecionar"
                                           v-model="newMember.personType"
                                           :disabled="formDisabled">
                                  <el-option class="select-success"
                                             value="NATURAL_PERSON"
                                             label="Pessoa Física"/>
                                  <el-option class="select-success"
                                             value="NATURAL_PERSON_E"
                                             label="Pessoa Física Estrangeira"/>
                                  <el-option class="select-info"
                                             value="LEGAL_PERSON"
                                             label="Pessoa Jurídica"/>
                                </el-select>
                              </fg-input>
                            </div>
                            <div class="col-md-2">
                              <fg-input :label="documentLabelByPersonType(newMember.personType)"
                                        type="tel"
                                        v-mask="[maskDefinitionByPersonType(newMember.personType)]"
                                        name="CPF/CNPJ/Passaporte Sócio"
                                        :error="getError('CPF/CNPJ/Passaporte Sócio')"
                                        v-model="newMember.socialNumber"
                                        :disabled="formDisabled"
                                        key="social-number">
                              </fg-input>
                            </div>
                            <div class="col-md-2">
                              <fg-input :label="isNaturalPerson(newMember.personType)?'Data de Nascimento':'Data de Abertura'"
                                        type="tel"
                                        v-mask="['##/##/####']"
                                        name="Data Sócio"
                                        :error="getError('Data Sócio')"
                                        v-model="newMember.birthday"
                                        :disabled="formDisabled"
                                        key="dn-representante">
                              </fg-input>
                            </div>
                            <div class="col-md-3">
                              <fg-input type="text"
                                        label="Nome Completo'"
                                        maxlength="70"
                                        name="Nome Sócio"
                                        :error="getError('Nome Sócio')"
                                        v-model="newMember.name"
                                        :disabled="formDisabled"
                                        key="nome-completo">
                              </fg-input>
                            </div>
                            <div class="col-md-2">
                              <label>% Participação</label>
                              <money class="form-control input-lg"
                                     v-model="newMember.participation"
                                     v-bind="fee"
                                     :disabled="formDisabled">
                              </money>
                            </div>
                            <div class="col-md-1">
                              <p-button type="info" style="margin-top: 25px" @click="insertList()">
                                <i slot="label" class="nc-icon nc-simple-add"></i>
                              </p-button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="card-body row">
                            <div class="col-md-12">
                              <span class="title">Lista de Sócios</span>
                            </div>
                            <div class="col-sm-12">
                              <el-table class="table-striped"
                                        :data="account.legalPerson.members"
                                        style="width: 100%"
                                        :header-cell-style="getHeaderStyle">
                                <el-table-column :min-width="20" label="ID">
                                  <template slot-scope="props">
                                    <span>
                                      <span><b>#{{props.row.id}}</b></span>
                                    </span>
                                  </template>
                                </el-table-column>
                                <el-table-column :min-width="40" label="Tipo">
                                  <template slot-scope="props">
                                    <span>
                                      <span>{{personTypeToDescription(props.row.personType)}}</span>
                                    </span>
                                  </template>
                                </el-table-column>
                                <el-table-column :min-width="70" label="CPF/CNPJ/Passaporte">
                                  <template slot-scope="props">
                                    <span>
                                      <span>{{props.row.socialNumber}}</span>
                                    </span>
                                  </template>
                                </el-table-column>
                                <el-table-column :min-width="140" label="Nome">
                                  <template slot-scope="props">
                                    <span>
                                      <span>{{props.row.name.toUpperCase()}}</span>
                                    </span>
                                  </template>
                                </el-table-column>
                                <el-table-column :min-width="60" label="Data Nascimento/Abertura">
                                  <template slot-scope="props">
                                    <span>
                                      <span>{{props.row.birthday}}</span>
                                    </span>
                                  </template>
                                </el-table-column>
                                <el-table-column :min-width="30" label="%">
                                  <template slot-scope="props">
                                    <span>
                                      <span>{{toMoney(props.row.participation)}} %</span>
                                    </span>
                                  </template>
                                </el-table-column>
                                <el-table-column :width="60" fixed="right" class-name="td-actions">
                                  <template slot-scope="props">
                                    <span v-if="!formDisabled">
                                      <p-button type="danger" size="sm" icon title="Excluir"
                                                @click="deleteList(props.row.id)">
                                        <i class="fas fa-trash"></i>
                                      </p-button>
                                    </span>
                                  </template>
                                </el-table-column>
                              </el-table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="isNaturalPerson(account.personType)">
              <div class="row">
                <div class="col-md-3">
                  <label class="control-label">Naturalidade</label>
                  <autocomplete ref="naturalidade"
                                name="Naturalidade"
                                placeholder="Selecionar"
                                v-validate="{required: true}"
                                key="naturalidade"
                                :error="getError('Naturalidade')"
                                :source="this.countriesNameAsc"
                                input-class="form-control"
                                results-value="code"
                                :results-display="displayCountryName"
                                @selected="selectNaturalidade">
                  </autocomplete>
                </div>
                <div class="col-md-2">
                  <div class="row" style="padding-left: 15px">
                    <fg-input type="tel"
                              v-mask="['##/##/####']"
                              label="Data de Nascimento"
                              name="Data de Nascimento"
                              v-validate="{dateBeforeValid:true, required: true, min:10}"
                              v-model="account.naturalPerson.birthDate"
                              :error="getError('Data de Nascimento')"
                              :disabled="formDisabled"
                              key="data-nascimento">
                    </fg-input>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3"
                     :style="account.naturalPerson.identificationDocument.documentType!=='RG'?'margin-bottom: 10px':''">
                  <fg-input label="Tipo de Documento" name="tipo documento" key="tipo-documento">
                    <el-select size="large"
                               placeholder="Selecionar"
                               v-model="account.naturalPerson.identificationDocument.documentType"
                               :disabled="formDisabled">
                      <el-option class="select-success"
                                 value="RG"
                                 label="RG"/>
                      <el-option class="select-success"
                                 value="CNH"
                                 label="CNH"/>
                      <el-option class="select-success"
                                 value="PASSPORT"
                                 label="Passaporte"/>
                    </el-select>
                  </fg-input>
                </div>
                <div class="col-md-3">
                  <fg-input type="text"
                            maxlength="20"
                            label="Número do Documento"
                            name="Número do Documento"
                            v-validate="{required: true}"
                            :error="getError('Número do Documento')"
                            v-model="account.naturalPerson.identificationDocument.number"
                            :disabled="formDisabled"
                            key="numero-documento">
                  </fg-input>
                </div>
                <div class="col-md-3" v-if="account.naturalPerson.identificationDocument.documentType==='RG'">
                  <fg-input type="text"
                            maxlength="40"
                            label="Orgão Expedidor"
                            name="Orgão Expedidor"
                            v-validate="{required: true}"
                            :error="getError('Orgão Expedidor')"
                            v-model="account.naturalPerson.identificationDocument.dispatchingAgency"
                            :disabled="formDisabled"
                            key="orgao-expeditor">
                  </fg-input>
                </div>
                <div class="col-md-3" v-if="account.naturalPerson.identificationDocument.documentType==='PASSPORT'">
                    <label class="control-label">País de emissão</label>
                    <autocomplete ref="dispatchingAg"
                                  name="País de Emissão"
                                  placeholder="Selecionar"
                                  v-validate="{required: true}"
                                  key="orgao-expeditor"
                                  :error="getError('País de Emissão')"
                                  :source="this.countriesPhoneCodeAsc"
                                  input-class="form-control"
                                  results-value="code"
                                  :disableInput="account.personType === 'NATURAL_PERSON'"
                                  :results-display="account.personType === 'NATURAL_PERSON' ? 'Brasil' : displayCountryName"
                                  @selected="selectDispatchingAgency">
                    </autocomplete>
                </div>
                <div class="col-md-3">
                  <div class="row" style="padding-left: 15px">
                    <fg-input type="tel"
                              v-mask="['##/##/####']"
                              label="Data de Expedição"
                              name="Data de Expedição"
                              v-validate="{dateBeforeValid:true, required: true, min:10}"
                              :error="getError('Data de Expedição')"
                              v-model="account.naturalPerson.identificationDocument.emissionDate"
                              :disabled="formDisabled"
                              key="data-expedicao"></fg-input>
                  </div>
                </div>
              </div>
              <div class="row" style="margin-bottom: 10px">
                <div class="col-md-3">
                  <fg-input label="Sexo" name="sexo" key="sexo">
                    <el-select size="large"
                               placeholder="Selecionar"
                               v-model="account.naturalPerson.gender"
                               :disabled="formDisabled">
                      <el-option class="select-success"
                                 value="MALE"
                                 label="Masculino"/>
                      <el-option class="select-info"
                                 value="FEMALE"
                                 label="Feminino"/>
                    </el-select>
                  </fg-input>
                </div>
                <div class="col-md-3">
                  <fg-input label="Estado Civil" name="estado civil" key="estado-civil">
                    <el-select size="large"
                               placeholder="Selecionar"
                               v-model="account.naturalPerson.maritalStatus"
                               :disabled="formDisabled">
                      <el-option class="select-info"
                                 value="SINGLE"
                                 label="Solteiro"/>
                      <el-option class="select-danger"
                                 value="MARRIED"
                                 label="Casado"/>
                      <el-option class="select-success"
                                 value="DIVORCED"
                                 label="Divorciado"/>
                      <el-option class="select-warning"
                                 value="WIDOWED"
                                 label="Viúvo"/>
                    </el-select>
                  </fg-input>
                </div>
              </div>
              <div class="row" v-if="account.naturalPerson.maritalStatus=='MARRIED'">
                <div class="col-md-12">
                  <fg-input type="text"
                            maxlength="70"
                            label="Nome do Cônjuge"
                            name="nome conjuge"
                            v-model="account.naturalPerson.partnerName"
                            :disabled="formDisabled"
                            key="conjuge">
                  </fg-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <fg-input type="text"
                            maxlength="70"
                            label="Nome da Mãe"
                            name="Nome da Mãe"
                            v-validate="{required: true}"
                            :error="getError('Nome da Mãe')"
                            v-model="account.naturalPerson.motherName"
                            :disabled="formDisabled"
                            key="nome-mae">
                  </fg-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <fg-input type="text"
                            maxlength="70"
                            label="Nome do Pai"
                            name="nome pai"
                            v-model="account.naturalPerson.fatherName"
                            :disabled="formDisabled"
                            key="nome-pai">
                  </fg-input>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <label class="control-label">Principal Ramo de Atividade</label>
                <autocomplete ref="ramo"
                              v-validate="{required: true}"
                              placeholder="Selecionar"
                              :source="cnaeList"
                              input-class="form-control"
                              results-value="code"
                              name="Ramo de Atividade"
                              :error="getError('Ramo')"
                              :results-display="cnaeDescription"
                              @selected="selectCnae"
                              key="ramo">
                </autocomplete>
                <div class="error-text" style="width: 100%;margin-top: 0.25rem;font-size: 80%;color: #dc3545;">{{ errors.first("Ramo de Atividade") }}</div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label class="control-label">Natureza Jurídica</label>
                <autocomplete ref="natureza"
                              v-validate="{required: true}"
                              name="Natureza Jurídica"
                              placeholder="Selecionar"
                              :source="legalNatureList"
                              input-class="form-control"
                              results-value="code"
                              :results-display="legalNatureDescription"
                              @selected="selectLegalNature"
                              key="natureza">
                </autocomplete>
                <div class="error-text" style="width: 100%;margin-top: 0.25rem;font-size: 80%;color: #dc3545;">{{ errors.first("Natureza Jurídica") }}</div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label class="control-label">Porte</label>
                <autocomplete ref="porte"
                              v-validate="{required: true}"
                              name="Porte"
                              placeholder="Selecionar"
                              :source="legalFormationList"
                              input-class="form-control"
                              results-value="code"
                              results-display="description"
                              @selected="selectLegalFormation"
                              key="porte">
                </autocomplete>
                <div class="error-text" style="width: 100%;margin-top: 0.25rem;font-size: 80%;color: #dc3545;">{{ errors.first("Porte") }}</div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <label>Faturamento Mensal</label>
                <money class="form-control input-lg"
                       v-model="account.monthlyAverage"
                       v-bind="money"
                       :disabled="formDisabled"
                       v-validate="{required: true, greaterThanZero: true}"
                       :class="{ 'is-invalid': errors.has('Faturamento Mensal') }"
                       name="Faturamento Mensal">
                </money>
                <div class="error-text" style="width: 100%;margin-top: 0.25rem;font-size: 80%;color: #dc3545;">{{ errors.first("Faturamento Mensal") }}</div>
              </div>
            </div>

            <div class="row" style="margin-top: 30px">
              <div class="col-md-5">
                <p-checkbox class="text-left" v-model="account.accountBusinessData.registered" key="terms">
                  Informar dados comerciais
                </p-checkbox>
              </div>
            </div>
            <div class="row" v-show="account.accountBusinessData.registered">
              <div class="col-md-6">
                <fg-input type="text"
                          maxlength="100"
                          label="Site"
                          v-model="account.accountBusinessData.site"
                          :disabled="formDisabled"
                          key="site">
                </fg-input>
              </div>
              <div class="col-md-6">
                <fg-input type="text"
                          maxlength="100"
                          label="Mídia Social"
                          v-model="account.accountBusinessData.socialMedia"
                          :disabled="formDisabled"
                          key="socialMedia">
                </fg-input>
              </div>
              <div class="col-md-6">
                <fg-input label="Área de Atuação">
                  <el-select
                    size="large"
                    v-model="account.accountBusinessData.businessSegment.id"
                    placeholder="Selecionar">
                    <el-option
                      class="select-default"
                      v-for="item in businessSegmentList"
                      :key="item.id"
                      :label="item.description"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </fg-input>
              </div>
              <div class="col-md-6">
                <fg-input label="Média de Faturamento">
                  <el-select
                    size="large"
                    v-model="account.accountBusinessData.averageBilling.id"
                    placeholder="Selecionar">
                    <el-option
                      class="select-default"
                      v-for="item in averageBillingList"
                      :key="item.id"
                      :label="item.description"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </fg-input>
              </div>
            </div>

            <br>
            <div class="row">
              <div class="col-md-12">
                <h5>Endereço</h5>
              </div>
              <div class="col-md-12">
                <p>Dados de endereço do titular da conta.</p>
              </div>
            </div>

            <div key="nationalAddress" v-if="!isForeignerAddressCheck">
              <div class="row" style="margin-top: 30px" v-if="isForeignerInputAvailable(this.account.personType)">
                <div class="col-md-5">
                  <p-checkbox class="text-left" v-model="isForeignerAddressCheck" key="terms">
                    Endereço fora do Brasil
                  </p-checkbox>
                </div>
              </div>
              <div class="row" style="margin-top: 30px">
                <div class="col-md-5">
                  <fg-input type="tel"
                            v-mask="['#####-###']"
                            label="CEP"
                            name="CEP"
                            v-validate="{required: true, min: 9}"
                            :error="getError('CEP')"
                            v-on:blur="this.searchZipCode"
                            v-model="account.address.zipCode"
                            :disabled="formDisabled"
                            key="cep">
                  </fg-input>
                </div>
                <div class="col-md-7">
                </div>
              </div>
              <div class="row">
                <div class="col-md-8">
                  <fg-input type="text"
                            maxlength="40"
                            :disabled="(viacep && viacep.logradouro) || formDisabled"
                            label="Rua/Av."
                            name="Rua/Av."
                            v-validate="{required: true}"
                            :error="getError('Rua/Av.')"
                            v-model="account.address.street"
                            key="rua">
                  </fg-input>
                </div>
                <div class="col-md-4">
                  <fg-input type="tel"
                            v-mask="['######']"
                            label="Número"
                            v-model="account.address.number"
                            :disabled="formDisabled"
                            key="numero">
                  </fg-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <fg-input type="text"
                            maxlength="40"
                            label="Complemento"
                            v-model="account.address.complement"
                            :disabled="formDisabled"
                            key="complemento">
                  </fg-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <fg-input type="text"
                            maxlength="40"
                            :disabled="(viacep && viacep.bairro) || formDisabled"
                            label="Bairro"
                            name="Bairro"
                            v-validate="{required: true}"
                            :error="getError('Bairro')"
                            v-model="account.address.neighborhood"
                            key="bairro">
                  </fg-input>
                </div>
                <div class="col-md-5">
                  <fg-input type="text"
                            label="Cidade"
                            disabled="true"
                            v-model="account.address.city"
                            key="cidade">
                  </fg-input>
                </div>
                <div class="col-md-2">
                  <fg-input type="text"
                            label="Estado"
                            disabled="true"
                            placeholder=""
                            v-model="account.address.state"
                            key="estado">
                  </fg-input>
                </div>
              </div>
            </div>

            <div key="foreignerAddress" v-if="isForeignerAddressCheck">
              <div class="row" style="margin-top: 30px">
                <div class="col-md-5">
                  <p-checkbox class="text-left" v-model="isForeignerAddressCheck" key="terms">
                    Endereço fora do Brasil
                  </p-checkbox>
                </div>
              </div>
              <div class="row" style="margin-top: 30px">
                <div class="col-md-5">
                  <fg-input type="tel"
                            label="ZipCode"
                            name="ZipCode"
                            maxlength="10"
                            :error="getError('ZipCode')"
                            v-model="account.address.zipCode"
                            :disabled="formDisabled"
                            key="zipCode">
                  </fg-input>
                </div>
                <div class="col-md-7"></div>
              </div>
              <div class="row">
                <div class="col-md-1">
                  <fg-input type="tel"
                            v-mask="['######']"
                            label="Número"
                            v-model="account.address.number"
                            :disabled="formDisabled"
                            key="numero">
                  </fg-input>
                </div>
                <div class="col-md-11">
                  <fg-input type="text"
                            maxlength="40"
                            :disabled="formDisabled"
                            label="Endereço"
                            name="Endereço"
                            v-validate="{required: true}"
                            :error="getError('Endereço')"
                            v-model="account.address.street"
                            key="rua">
                  </fg-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <fg-input type="text"
                            label="Cidade"
                            :disabled="formDisabled"
                            v-model="account.address.city"
                            key="cidade">
                  </fg-input>
                </div>
                <div class="col-md-2">
                  <fg-input type="text"
                            label="Estado"
                            :disabled="formDisabled"
                            placeholder=""
                            v-model="account.address.state"
                            key="estado">
                  </fg-input>
                </div>
                <div class="col-md-4">
                  <label class="control-label">País</label>
                  <autocomplete ref="pais"
                                name="pais"
                                placeholder="Selecionar"
                                key="pais"
                                :source="this.countriesNameAsc"
                                input-class="form-control"
                                results-value="code"
                                :results-display="displayCountryName"
                                @selected="selectPais">
                  </autocomplete>
                </div>
              </div>
            </div>

            <br>
            <div class="row">
              <div class="col-md-12">
                <h5>Dados Bancários</h5>
              </div>
              <div class="col-md-12">
                <p>Os dados bancários fornecidos devem ser necessariamente do mesmo titular da conta SuitPay.</p>
              </div>

              <div class="col-md-5" v-if="isForeignerBankAccountAvailable(this.account.personType)">
                <p-checkbox class="text-left" v-model="isBankAccountForeigner" key="terms">
                  Conta Estrangeira
                </p-checkbox>
              </div>
            </div>

            <div key="nationalBankAccount" v-if="!isBankAccountForeigner">
              <div class="row" style="margin-top: 10px">
                <div class="col-md-12">
                  <label class="control-label">Banco</label>
                  <autocomplete ref="bankAc"
                                v-validate="{required: true}"
                                name="Banco"
                                placeholder="Selecionar"
                                :source="banksCodes"
                                input-class="form-control"
                                results-value="code"
                                :results-display="displayBankName"
                                @selected="selectBank">
                  </autocomplete>
                  <div class="error-text" style="width: 100%;margin-top: 0.25rem;font-size: 80%;color: #dc3545;">{{ errors.first("Banco") }}</div>
                </div>

                <div class="col-md-12">
                  <label class="control-label">Tipo de Conta</label>
                  <el-select size="large" placeholder="Selecionar" style="width: 100%"
                             v-model="account.bankDetails.accountType">
                    <el-option class="select-success"
                               value="CC"
                               label="Corrente"/>
                    <el-option class="select-info"
                               value="PP"
                               label="Poupança"/>
                  </el-select>
                </div>
              </div>
              <div class="row" style="margin-top: 10px">
                <div class="col-md-8">
                  <label class="control-label">Agência</label>
                  <fg-input v-model="account.bankDetails.agencyNumber" style="width: 100%"
                            v-validate="{required: true, agencyBankValid: true}" type="tel" maxlength="5"
                            :error="getError('Agência Destino')"
                            name="Agência Destino" v-mask="['#####']">
                  </fg-input>
                </div>
                <div class="col-md-4">
                  <label class="control-label">Dígito da Agência</label>
                  <fg-input v-model="account.bankDetails.agencyDv" style="width: 100%"
                            type="tel" maxlength="1"
                            name="Dígito" v-mask="['#']">
                  </fg-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-8">
                  <label class="control-label">Número da Conta</label>
                  <fg-input v-model="account.bankDetails.accountNumber" style="width: 100%"
                            v-validate="{required: true}" type="tel" maxlength="12"
                            :error="getError('Conta Destino')"
                            name="Conta Destino" v-mask="['############']">
                  </fg-input>
                </div>
                <div class="col-md-4">
                  <label class="control-label">Dígito da Conta</label>
                  <fg-input v-model="account.bankDetails.accountNumberDv" style="width: 100%"
                            v-validate="{required: true}"
                            type="tel" maxlength="1" :error="getError('Dígito')"
                            name="Dígito" v-mask="['#']">
                  </fg-input>
                </div>
              </div>
            </div>

            <div key="foreignerBankAccount" v-if="isBankAccountForeigner">
              <div class="row" style="margin-top: 10px">
                <div class="col-md-12">
                  <label class="control-label">Instituição Financeira</label>
                  <fg-input v-model:class="account.bankDetails.institutionName"
                            v-validate="{required: true}"
                            name="Instituição Financeira"
                            input-class="form-control">
                  </fg-input>
                  <div class="error-text" style="width: 100%;margin-top: 0.25rem;font-size: 80%;color: #dc3545;">{{ errors.first("Instituição Financeira") }}</div>
                </div>
              </div>
              <div class="row" style="margin-top: 10px">
                <div class="col-md-8">
                  <label class="control-label">IBAN (International Bank Account Number)</label>
                  <fg-input v-model="account.bankDetails.iban" style="width: 100%"
                            v-validate="{required: true}" maxlength="34"
                            :error="getError('IBAN')"
                            name="IBAN">
                  </fg-input>
                </div>
                <div class="col-md-4">
                  <label class="control-label">Código SWIFT</label>
                  <fg-input v-model="account.bankDetails.swiftCode" style="width: 100%"
                            type="tel"
                            name="Swift Code">
                  </fg-input>
                </div>
              </div>

            </div>

            <br/>
            <div class="row" v-if="showAgreementCb">
              <div class="col-md-5">
                <p-checkbox class="text-left" v-model="acceptTerms" key="terms">
                  Eu aceito os <a v-bind:href="urlAgreement"
                                  target="_blank">termos e condições</a>.
                </p-checkbox>
              </div>
            </div>
            <br/>

            <div class="text-center">
              <button type="submit" class="btn btn-info btn-fill btn-wd" :disabled="buttonDisabled">
                Salvar Dados
              </button>
            </div>

            <div class="clearfix"></div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ResetPassword from './Profile/ResetPassword'
import UploadDoc from "./Profile/UploadDoc"
import swal from 'sweetalert2'
import {callWs, postWs, getWs} from 'src/ws.service'
import {isNullOrEmpty, isValidCnpj, isValidCpf, isValidBeforeDate, toMoney} from 'src/util/core.utils'
import {phoneNumberMask, isLocalPhoneNumber, cleanNumber} from 'src/util/phone.utils'
import Autocomplete from 'vuejs-auto-complete'
import banksCodes from "../Transfer/banks-codes"
import VueQrcode from "@chenfengyuan/vue-qrcode";
import DerivedAccount from "./Profile/DerivedAccount";
import {formatDate} from "../../../../util/date.utils";
import {sendEvent} from "@/googleAnalyticsApi";
import countries from "../User/countries"

export default {
  components: {
    DerivedAccount,
    ResetPassword,
    UploadDoc,
    Autocomplete,
    VueQrcode
  },
  data() {
    return {
      file: '',
      fileMaxSize: 3,
      showEditProfile: false,
      showEditDocuments: false,
      showProfile: false,
      showOptions: true,
      showWaitingApproval: false,
      buttonProfileDisabled: false,
      buttonDocumentDisabled: true,
      buttonKycValidationDisabled: true,
      showCreateDerivedAccount: false,
      uploadDocKey: 0,
      usernameDisplay: '',
      email: '',
      statusAccount: '',
      avatarBase64: '',
      avatarFileType: '',
      showAvatar: false,
      viacep: {
        logradouro: '',
        bairro: '',
        ibge: '',
        localidade: '',
        uf: '',
      },
      buttonDisabled: false,
      formDisabled: false,
      showAgreementCb: true,
      acceptTerms: false,
      urlAgreement: 'https://web.suitpay.app/static/pdf/CONTRATO_PESSOA_FISICA.pdf',
      name: '',
      isForeigner: false,
      isForeignerAddressCheck: false,
      isBankAccountForeigner: false,
      account: {
        name: '',
        socialNumber: '',
        personType: 'NATURAL_PERSON',
        phoneNumber1: '',
        phoneNumber2: '',
        accountDocumentStatus: '',
        address: {
          codIbge: '',
          street: '',
          number: '',
          complement: '',
          zipCode: '',
          neighborhood: '',
          city: '',
          state: '',
          country: '',
          isForeignerAddress: ''
        },
        legalPerson: {
          companyName: '',
          openingDate: '',
          mainActivity: '',
          procuratorSocialNumber: '',
          procuratorBirthday: '',
          members:[]
        },
        naturalPerson: {
          motherName: '',
          fatherName: '',
          birthDate: '',
          birthplace: '',
          gender: 'MALE',
          maritalStatus: 'SINGLE',
          partnerName: '',
          profession: '',
          identificationDocument: {
            documentType: 'RG',
            number: '',
            emissionDate: '',
            dispatchingAgency: ''
          }
        },
        accountBusinessData: {
          registered: false,
          site: '',
          socialMedia: '',
          averageBilling: {
            id: '',
            description: '',
          },
          businessSegment: {
            id: '',
            description: '',
          },
        },
        cnae: '',
        legalNature: '',
        legalFormation: '',
        bankDetails: {
          username: '',
          bankCode: '',
          accountType: '',
          agencyNumber: '',
          agencyDv: '',
          accountNumber: '',
          accountNumberDv: '',
          isForeignerAccount: '',
          institutionName: '',
          iban: '',
          swiftCode: ''
        },
        monthlyAverage: 0,
      },
      averageBillingList: [],
      businessSegmentList: [],
      cnaeList: [],
      legalNatureList: [],
      legalFormationList: [],
      newMember: {
        id: 0,
        personType: 'NATURAL_PERSON',
        name: '',
        socialNumber: '',
        birthday: '',
        participation: 0,
      },
      fee: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: ' %',
        precision: 2,
        masked: false
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false
      },
      banksCodes: banksCodes,
      onboardingId: '',
      accountStatus: null,
      kycIntervalLoop: null,
      derivedAccount: {
        newUsername: '',
        suitIdEnabled: false,
        isDerivedAccountEnabled: false,
        subAccounts : [],
      },
      facebook:{
        fbc: this.getCookie("_fbc"),
        fbp: this.getCookie("_fbp"),
      },
      countriesPhoneCodeAsc: this.countriesSorted(countries),
      countriesNameAsc: this.countriesSortedByName(countries)
    }
  },
  watch: {
    'account.accountDocumentStatus': (status) => {
      if (status !== 'PENDING_KYC') {
        clearInterval(this.kycIntervalLoop)
      }
    }
  },
  mounted() {
    this.accountStatus = localStorage.getItem("accountStatus")
    if (this.accountStatus !== null && this.accountStatus !== 'null') {
      if (this.accountStatus === 'PENDING_KYC') {
        this.kycIntervalLoop = setInterval(() => {
          this.loadAccountMethod()
        }, 5 * 1000)
      } else {
        clearInterval(this.kycIntervalLoop)
      }
    }
  },
  created() {
    this.$validator.extend('cpfCnpjValid', {
      getMessage: field => ('O ' + field + ' é inválido.'),
      validate: (value) => {
        if (isNullOrEmpty(value)) {
          return false
        }
        value = value.replace(/(\.)|(\/)|(-)/g, '')
        return isValidCnpj(value) || isValidCpf(value)
      }
    })

    this.$validator.extend('agencyBankValid', {
      getMessage: field => ('O ' + field + ' é inválido.'),
      validate: (value) => {
        if (isNullOrEmpty(value)) {
          return false
        }
        if(/^0+$/.test(value)) {
          return false
        }
        return true;
      }
    })

    this.$validator.extend('greaterThanZero', {
      getMessage: field => ('O ' + field + ' deve ser maior que zero.'),
      validate: (value) => {
        if (parseFloat(value)<=0) {
          return false
        }
        return true;
      }
    })

    this.$validator.extend('dateBeforeValid', {
      getMessage: field => (field + ' inválida! Verifique o valor informado.'),
      validate: (value) => {
        return isValidBeforeDate(value)
      }
    })

    this.usernameDisplay = "@" + localStorage.getItem("usernameDisplay")
    this.account.name = localStorage.getItem("name")
    this.email = localStorage.getItem("email")
    this.avatarBase64 = localStorage.getItem("avatarBase64")
    this.avatarFileType = localStorage.getItem("avatarFileType")
    if(isNullOrEmpty(this.avatarFileType)){
      this.showAvatar = false
    } else {
      this.showAvatar = true
    }
    postWs("/acc/get-account", true, null, null,
      this.loadAccount, function () {
      })
    getWs("/acc/get-cnae-list", true, null, (response) => {this.cnaeList = response.data}, function () {
    })
    getWs("/acc/get-legal-nature-list", true, null, (response) => {this.legalNatureList = response.data}, function () {
    })
    getWs("/acc/get-legal-formation-list", true, null, (response) => {this.legalFormationList = response.data}, function () {
    })
    getWs("/acc/get-derived-accounts", true, null, (response) => {this.populateDerivedAccountInfo(response)}, function () {})
    this.$validator.reset()
  },
  computed: {
    buttonCreateSubaccountDisabled() {
      return (this.account.accountDocumentStatus === 'APPROVED' && this.account.personType === 'LEGAL_PERSON' && this.derivedAccount.isDerivedAccountEnabled)
    }
  },
  methods: {
    loadAccountMethod() {
      postWs("/acc/get-account", true, null, null,
        this.loadAccount, function () {
        })
    },
    toMoney,
    loadAccount(response) {
      this.account = response.data
      localStorage.setItem('name', this.account.name)
      localStorage.setItem('accountStatus', this.account.accountDocumentStatus)
      localStorage.setItem('isForeignerBankAccount', this.account.bankDetails.isForeignerAccount)
      if (this.account.accountDocumentStatus === 'APPROVED') {
        this.buttonDisabled = true
        this.formDisabled = true
        this.showEditProfile = false
        this.showEditDocuments = false
        this.showProfile = true
        this.showOptions = false
        this.buttonProfileDisabled = true
        this.buttonKycValidationDisabled = true
        this.buttonDocumentDisabled = true
        this.showWaitingApproval = false
        this.statusAccount = 'Status: Conta Ativa'
      } else if (this.account.accountDocumentStatus === 'PENDING_DOCUMENT') {
        this.showEditProfile = false
        this.showEditDocuments = false
        this.showProfile = false
        this.showOptions = true
        this.showWaitingApproval = false
        if (this.account.socialNumber != null && this.account.socialNumber !== 'null' && this.account.socialNumber !== '') {
          this.buttonProfileDisabled = true
          this.buttonDocumentDisabled = false
          this.buttonKycValidationDisabled = true
        } else {
          this.buttonProfileDisabled = false
          this.buttonDocumentDisabled = true
          this.buttonKycValidationDisabled = true
        }
        if(this.account.accountBusinessData != null) {
          this.averageBillingList = this.account.accountBusinessData.averageBillingList
          this.businessSegmentList = this.account.accountBusinessData.businessSegmentList
        }
        this.statusAccount = 'Status: Conta Pendente de Documentação'
      } else if (this.account.accountDocumentStatus === 'PENDING_KYC') {
        getWs("/acc/id-cerberus/get-onboarding-id", true, null, (response) => {
          this.setOnboardingFlags(response)
        }, function () {
          swal({
            title: 'Aviso!',
            text: 'Falha ao iniciar a fase de Verificação Biométrica. Tente novamente mais tarde!',
            buttonsStyling: true,
            timer: 10000,
            confirmButtonClass: 'btn btn-danger btn-fill',
            type: 'warning'
          })
        })
        this.statusAccount = 'Status: Conta Pendente de Verificação Biométrica'
      } else if ( this.account.accountDocumentStatus === 'PENDING_VALIDATION' ||
        this.account.accountDocumentStatus === 'PENDING_VALIDATION_KYC' ||
        this.account.accountDocumentStatus === 'KYC_AT_APPROVAL' ||
        this.account.accountDocumentStatus === 'KYC_MANUAL_APPROVAL') {
        this.showEditProfile = false
        this.showEditDocuments = false
        this.showProfile = false
        this.showOptions = false
        this.buttonProfileDisabled = true
        this.buttonDocumentDisabled = true
        this.buttonKycValidationDisabled = true
        this.showWaitingApproval = true
        this.statusAccount = 'Status: Conta em Análise'
      } else if ( this.account.accountDocumentStatus === 'REJECTED_AT_PRE_APPROVAL' ||
        this.account.accountDocumentStatus === 'KYC_REJECTED') {
        this.showEditProfile = false
        this.showEditDocuments = false
        this.showProfile = false
        this.showOptions = false
        this.buttonProfileDisabled = true
        this.buttonDocumentDisabled = true
        this.buttonKycValidationDisabled = true
        this.showWaitingApproval = true
        this.statusAccount = 'Status: Conta recusada na pré análise'
      }
      if (!isNullOrEmpty(this.account.address.codIbge)) {
        this.viacep.logradouro = this.account.address.street
        this.viacep.bairro = this.account.address.neighborhood
      }
      if (this.isNaturalPerson(this.account.personType) && !isNullOrEmpty(this.account.naturalPerson.motherName)) {
        this.showAgreementCb = false;
      } else if (this.isLegalPerson(this.account.personType) && !isNullOrEmpty(this.account.legalPerson.mainActivity)) {
        this.showAgreementCb = false;
      }
      if (isNullOrEmpty(this.account.bankDetails.isForeignerAccount)) {
        this.isBankAccountForeigner = false
      } else {
        this.isBankAccountForeigner = this.account.bankDetails.isForeignerAccount
      }
      if (isNullOrEmpty(this.account.address.isForeignerAddress)) {
        this.isForeignerAddressCheck = false
      } else {
        this.isForeignerAddressCheck = this.account.address.isForeignerAddress
      }
      this.$validator.reset()
    },
    setOnboardingFlags(response){
      this.onboardingId = response.data
      this.showEditProfile = false
      this.showEditDocuments = false
      this.showProfile = false
      this.showOptions = true
      this.buttonProfileDisabled = true
      this.buttonDocumentDisabled = true
      this.buttonKycValidationDisabled = false
      this.showWaitingApproval = false
    },
    selectCnae(cnae){
      this.account.cnae = cnae.value
    },
    cnaeDescription(cnae){
      return cnae.code + ' - ' + cnae.description
    },
    selectLegalNature(legalNature){
      this.account.legalNature = legalNature.value
    },
    legalNatureDescription(cnae){
      return cnae.code + ' - ' + cnae.description
    },
    selectLegalFormation(legalFormation){
      this.account.legalFormation = legalFormation.value
    },
    countriesSorted(countries) {
      countries = countries.sort((a, b) => (a.ddi > b.ddi) ? 1 : -1).filter(function (el) {
        return el.ddi != null;
      })
      countries.push(countries.shift())
      countries.unshift({
        id: 26,
        name: "Brasil",
        number_iso: "BRA",
        formal_name: "República Federativa do Brasil",
        ddi: 55
      })
      return countries
    },
    countriesSortedByName(countries) {
      countries = countries.sort((a, b) => (a.name > b.name) ? 1 : -1).filter(function (el) {
        return el.ddi != null;
      })
      countries.unshift({
        id: 26,
        name: "Brasil",
        number_iso: "BRA",
        formal_name: "República Federativa do Brasil",
        ddi: 55
      })
      return countries
    },
    displayCountryName(country) {
      return country.name
    },
    mask(phone) {
      return phoneNumberMask(phone)
    },
    isLocalPhoneNumber(phone) {
      return isLocalPhoneNumber(phone)
    },
    saveAccount() {
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          return
        }
        if(this.isLegalPerson(this.account.personType)
          && (this.account.legalPerson.members == null || this.account.legalPerson.members.length === 0)){
          swal({
            title: 'Aviso!',
            text: 'Os dados dos sócios não podem ser vazios.',
            buttonsStyling: true,
            timer: 10000,
            confirmButtonClass: 'btn btn-danger btn-fill',
            type: 'warning'
          })
          return
        }
        if (isNullOrEmpty(this.account.address.codIbge)) {
          if (this.isForeignerAddressCheck === false) {
            swal({
              title: 'Aviso!',
              text: 'CEP não encontrado nos Correios, favor verifique os dados e tente novamente!',
              buttonsStyling: true,
              timer: 10000,
              confirmButtonClass: 'btn btn-danger btn-fill',
              type: 'warning'
            })
            return
          } else {
            this.account.address.codIbge = ''
          }
        }
        if (this.isForeignerAddressCheck === true && isNullOrEmpty(this.account.address.country)) {
          swal({
            title: 'Aviso!',
            text: 'O campo País em caso de endereço fora do Brasil não pode ser nulo!',
            buttonsStyling: true,
            timer: 10000,
            confirmButtonClass: 'btn btn-danger btn-fill',
            type: 'warning'
          })
          return
        }
        if (this.account.naturalPerson.identificationDocument.documentType==='PASSPORT' &&
          isNullOrEmpty(this.account.naturalPerson.identificationDocument.dispatchingAgency)) {
          swal({
            title: 'Aviso!',
            text: 'O campo País de Emissão em caso de documento ser Passaporte não pode ser nulo!',
            buttonsStyling: true,
            timer: 10000,
            confirmButtonClass: 'btn btn-danger btn-fill',
            type: 'warning'
          })
          return
        }
        if (this.isForeigner === false) {
          this.account.naturalPerson.birthplace = "Brasil"
        }
        if (isNullOrEmpty(this.account.naturalPerson.birthplace)) {
          swal({
            title: 'Aviso!',
            text: 'O campo Naturalidade não pode ser nulo',
            buttonsStyling: true,
            timer: 10000,
            confirmButtonClass: 'btn btn-danger btn-fill',
            type: 'warning'
          })
          return
        }
        if (isNullOrEmpty(this.account.bankDetails.bankCode)) {
          if (this.isBankAccountForeigner === false) {
            swal({
              title: 'Aviso!',
              text: 'O campo Banco é obrigatório.',
              buttonsStyling: true,
              timer: 10000,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            return
          } else {
            this.account.bankDetails.bankCode = '0'
          }
        }
        if (isNullOrEmpty(this.account.bankDetails.accountType)) {
          if (this.isBankAccountForeigner === false) {
            swal({
              title: 'Aviso!',
              text: 'O campo Tipo de Conta é obrigatório.',
              buttonsStyling: true,
              timer: 10000,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            return
          } else {
            this.account.bankDetails.accountType = '0'
          }
        }
        if (/^0+$/.test(this.account.bankDetails.accountNumber)) {
          if (this.isBankAccountForeigner === false) {
            swal({
              title: 'Aviso!',
              text: 'O campo Tipo de Conta é obrigatório.',
              buttonsStyling: true,
              timer: 10000,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            return
          } else {
            this.account.bankDetails.accountNumber = '0'
          }
        }
        if (this.isBankAccountForeigner === true && isNullOrEmpty(this.account.bankDetails.institutionName)) {
          swal({
            title: 'Aviso!',
            text: 'O campo Instituição Financeira é obrigatório.',
            buttonsStyling: true,
            timer: 10000,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        if (this.isBankAccountForeigner === true && isNullOrEmpty(this.account.bankDetails.iban)) {
          swal({
            title: 'Aviso!',
            text: 'O campo IBAN é obrigatório.',
            buttonsStyling: true,
            timer: 10000,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        if (this.isBankAccountForeigner === true && isNullOrEmpty(this.account.bankDetails.swiftCode)) {
          swal({
            title: 'Aviso!',
            text: 'O campo SWIFT Code é obrigatório.',
            buttonsStyling: true,
            timer: 10000,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        if (this.showAgreementCb && !this.acceptTerms) {
          swal({
            title: "Aviso",
            text: 'Você deve aceitar os Termos e Condições antes de prosseguir.',
            buttonsStyling: true,
            timer: 10000,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        this.account.bankDetails.isForeignerAccount = this.isBankAccountForeigner === true ? 'true' : 'false'
        this.account.address.isForeignerAddress = this.isForeignerAddressCheck === true ? 'true' : 'false'
        this.buttonDisabled = true
        postWs("/acc/save-account", true, null, this.account,
          () => {
            localStorage.setItem("name", this.account.name)
            this.sendPersonalDataPixelEvent()
            sendEvent('realizou_cadastro', localStorage.getItem("username"), this.email, this.account.phoneNumber1)
            this.showEditProfile = false
            this.showEditDocuments = false
            this.showProfile = false
            this.showOptions = true
            this.buttonProfileDisabled = true
            this.buttonDocumentDisabled = false
            swal({
              title: 'Sucesso!',
              text: 'Dados salvos com sucesso.',
              buttonsStyling: true,
              timer: 5000,
              confirmButtonClass: 'btn btn-success btn-fill',
              type: 'success'
            })
          }, (error) => {
            this.buttonDisabled = false
            if (error.response.status == 304) {
              swal({
                title: 'Aviso!',
                text: 'CPF/CNPJ já cadastrado.',
                buttonsStyling: true,
                timer: 5000,
                confirmButtonClass: 'btn btn-warning btn-fill',
                type: 'warning'
              })
              return
            }
            swal({
              title: 'Falha!',
              text: 'Erro ao salvar dados da conta, confira os dados e tente novamente.',
              buttonsStyling: true,
              timer: 5000,
              confirmButtonClass: 'btn btn-danger btn-fill',
              type: 'error'
            })
          })
      })
    },
    searchZipCode() {
      this.viacep = null
      this.account.address.codIbge = ""
      this.account.address.city = ""
      this.account.address.state = ""
      getWs("/cep/get-cep",
        true, {cep: this.account.address.zipCode},
        (response) => {
          this.viacep = response.data
          this.account.address.codIbge = this.viacep.ibge
          if (this.viacep.logradouro.length > 40) {
            this.account.address.street = this.viacep.logradouro.substr(0, 40)
          } else {
            this.account.address.street = this.viacep.logradouro
          }
          if(this.viacep.bairro.length > 40) {
            this.account.address.neighborhood = this.viacep.bairro.substr(0, 40)
          } else {
            this.account.address.neighborhood = this.viacep.bairro
          }
          this.account.address.city = this.viacep.localidade
          this.account.address.state = this.viacep.uf
        },
        (e) => {
          swal({
            title: 'Aviso!',
            text: 'CEP não encontrado nos Correios, favor verifique os dados e tente novamente!',
            buttonsStyling: true,
            timer: 10000,
            confirmButtonClass: 'btn btn-danger btn-fill',
            type: 'warning'
          })
          return
        })
    },
    changePersonType() {
      localStorage.setItem('personType', this.account.personType)
      ++this.uploadDocKey
      if (this.isNaturalPerson(this.account.personType)) {
        this.urlAgreement = 'https://web.suitpay.app/static/pdf/CONTRATO_PESSOA_FISICA.pdf';
      } else {
        this.urlAgreement = 'https://web.suitpay.app/static/pdf/CONTRATO_PESSOA_JURIDICA.pdf';
      }
      this.$validator.reset()
    },
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    openProfileEdit(){
      this.showEditProfile = true
      this.showEditDocuments = false
      this.showProfile = false
      this.showOptions = false
      this.buttonProfileDisabled = true
      this.buttonDocumentDisabled = true
    },
    openCreateDerivedAccount(){
      this.showProfile = false
      this.showCreateDerivedAccount = true
    },
    handleFileUpload(){
      if(this.$refs.file.files[0].size > (this.fileMaxSize * 1024 * 1024)) {
        swal({
          title: 'Aviso!',
          text: 'A imagem deve possuir no máximo ' + this.fileMaxSize.toString() + 'MB',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
        return
      }
      this.file = this.$refs.file.files[0]
      swal({
        title: 'Alterar Imagem da Conta',
        text: "Deseja realmente atualizar a imagem da sua conta?.",
        type: 'warning',
        buttonsStyling: true,
        confirmButtonClass: 'btn-success btn-fill',
        showCancelButton: true,
        confirmButtonColor: '#667eea',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Atualizar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result) {
          this.callUploadWsAvatar()
        }
      })
    },
    callUploadWsAvatar() {
      let formData = new FormData()
      formData.append("file", this.file)
      callWs("/document/upload",
        "POST", {'Content-Type': 'multipart/form-data'},
        true, {fileType: 'AVATAR'}, formData,
        response => {
          swal({
            title: 'Sucesso!',
            text: 'Imagem salva com sucesso.',
            buttonsStyling: true,
            timer: 3000,
            confirmButtonClass: 'btn btn-success btn-fill',
            type: 'success'
          })
          localStorage.setItem("avatarBase64", response.data.imageBase64)
          localStorage.setItem("avatarFileType", response.data.imageFileType)
          setTimeout(() => {this.$router.go(0)}, 3000)
        },
        (error) => {
          let text = 'Falha ao salvar a imagem, por favor tente novamente!'
          if (error.response.data.descriptionError == 'WRONG_DATA_SIZE') {
            text = 'Falha ao salvar a imagem, verifique o tamanho do arquivo e tente novamente!'
          } else if(error.response.data.descriptionError == 'WRONG_DATA_FORMAT') {
            text = 'Falha ao salvar a imagem, verifique o formato do arquivo e tente novamente!'
          }
          swal({
            title: 'Falha!',
            text: text,
            buttonsStyling: true,
            timer: 5000,
            confirmButtonClass: 'btn btn-danger btn-fill',
            type: 'error'
          })
        }
      )
    },
    loadDocSrc() {
      let fileType = this.avatarFileType.replace(" ", "").toLowerCase()
      if (fileType == 'jpg') {
        return 'data:image/jpeg;base64,' + this.avatarBase64
      }
      return 'data:image/' + fileType + ';base64,' + this.avatarBase64
    },
    getHeaderStyle(){
      return 'font-size:12px';
    },
    insertList() {
      let cpf = this.newMember.socialNumber.replace(/(\.)|(\/)|(-)/g, '')
      if((this.newMember.personType === 'NATURAL_PERSON' && !isValidCpf(cpf)) || (this.newMember.personType === 'LEGAL_PERSON' && !isValidCnpj(cpf))) {
        swal({
          title: 'Aviso!',
          text: 'O CPF/CNPJ é inválido!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
        return
      }

      if(!isValidBeforeDate(this.newMember.birthday)) {
        swal({
          title: 'Aviso!',
          text: 'A data é inválida!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
        return
      }

      if(isNullOrEmpty(this.newMember.name)) {
        swal({
          title: 'Aviso!',
          text: 'É necessário informar o nome do sócio!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
        return
      }

      if(this.newMember.participation == 0) {
        swal({
          title: 'Aviso!',
          text: 'É necessário informar o percentual de participação societária do sócio!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
        return
      }

      if(this.account.legalPerson.members == null){
        this.account.legalPerson.members = []
      }

      this.newMember.id++
      this.account.legalPerson.members.push({
        id: this.newMember.id,
        personType: this.newMember.personType,
        name: this.newMember.name,
        socialNumber: this.newMember.socialNumber,
        birthday: this.newMember.birthday,
        participation: this.newMember.participation
      })

      this.newMember.name = ''
      this.newMember.socialNumber = ''
      this.newMember.birthday = ''
      this.newMember.participation = 0
    },

    deleteList(id){
      let index = 0
      this.account.legalPerson.members.forEach(value => {
        if(value.id === id) {
          this.account.legalPerson.members.splice(index, 1);
        }
        index++
      })

      index = 0
      this.account.legalPerson.members.forEach(value => {
        if(value.id > id) {
          value.id = value.id - 1
        }
        index++
      })
      this.newMember.id--
    },
    personTypeToDescription(personType){
      switch (personType) {
        case 'LEGAL_PERSON':
          return 'Pessoa Jurídica'
        case 'NATURAL_PERSON':
          return 'Pessoa Física'
        case 'NATURAL_PERSON_E':
          return 'P. Física Estrangeira'
      }
      return ''
    },
    getBank(code) {
      for (let bank in this.banksCodes) {
        if (this.banksCodes[bank].code === code) {
          return this.banksCodes[bank]
        }
      }
      return null
    },
    selectBank(bank) {
      this.account.bankDetails.bankCode = bank.selectedObject.code
    },
    selectDispatchingAgency(country) {
      this.account.naturalPerson.identificationDocument.dispatchingAgency = country.selectedObject.name
    },
    selectNaturalidade(country) {
      this.account.naturalPerson.birthplace = country.selectedObject.name
    },
    selectPais(country) {
      this.account.address.country = country.selectedObject.name
    },
    displayBankName(bank) {
      return bank.code + ' - ' + bank.name
    },
    populateDerivedAccountInfo(response) {
      this.derivedAccount.newUsername = response.data.newUsername
      this.derivedAccount.suitIdEnabled = response.data.suitIdEnabled
      this.derivedAccount.isDerivedAccountEnabled = response.data.isDerivedAccountEnabled
      this.derivedAccount.subAccounts = response.data.subAccounts
      this.derivedAccount.subAccounts.forEach((sub) => {
        sub.createdDate = formatDate(new Date(sub.createdDate), "DD/MM/YYYY")
      })
      this.derivedAccount.subAccounts.sort()
    },
    isNaturalPerson(personType) {
      return personType === 'NATURAL_PERSON' || personType === 'NATURAL_PERSON_E';
    },
    isLegalPerson(personType) {
      return personType === 'LEGAL_PERSON';
    },
    isForeignerInputAvailable(personType) {
      return personType === 'NATURAL_PERSON_E'
    },
    isForeignerBankAccountAvailable(personType) {
      return personType === 'NATURAL_PERSON_E' || personType === 'LEGAL_PERSON'
    },
    documentLabelByPersonType(personType) {
      switch (personType) {
        case 'NATURAL_PERSON': return 'CPF'
        case 'LEGAL_PERSON': return 'CNPJ'
        case 'NATURAL_PERSON_E': return 'CPF/Passaporte'
      }
    },
    maskDefinitionByPersonType(personType) {
      switch (personType) {
        case 'NATURAL_PERSON': return '###.###.###-##'
        case 'LEGAL_PERSON': return '##.###.###/####-##'
        case 'NATURAL_PERSON_E': return 'XXXXXXXXXXX'
      }
    },
    async sendPersonalDataPixelEvent() {
      try {
        postWs('/facebook/pixel/dados-cadastrais',
          false,
          null,
          {
            name: this.account.name,
            email: this.email,
            phoneNumber: this.account.phoneNumber1,
            fbc: this.facebook.fbc,
            fbp: this.facebook.fbp,
            cidade: this.account.address.city,
            username: localStorage.getItem("username")
          }, function (response){
          }, function (error){
            console.log(error)
          })
      } catch (err) {
        console.error('Error:', err);
      }
    },
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
  }
}

</script>
<style lang="scss">
.dzs-profile {
  a.fileContainer {
    overflow: hidden;
    position: relative;
    cursor: pointer;
    display: inline-block;
    color: lightskyblue;
  }

  a.fileContainer:hover {
    text-decoration: underline;
    color: blue;
    filter: contrast(40%);
  }

  a.fileContainer > input[type=file] {
    cursor: inherit;
    filter: alpha(opacity=0);
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: right;
  }

  .el-input__inner {
    background-color: #fff !important;
  }

  .is-invalid {
    background-color: #FFC0A4;
    border: 1px solid #ef8157;
    color: #ef8157;
  }

  .is-invalid:focus {
    background-color: #FFFFFF;
    border: 1px solid #ef8157;
  }
}

</style>
