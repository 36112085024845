<template>
  <div class="dzs-ted">
    <div class="row" style="padding: 15px">
      <div class="col-md-5">
        <card style="padding: 5%; height: 100%">
          <h4 slot="header" class="card-title">
            Transferência via PIX
          </h4>
          <Banner></Banner>
          <br>
          <br>
          <p>
            As solicitações de PIX podem ser realizadas todos os dias, em qualquer horário.
          </p>
          <p>
            Para realizar o PIX basta escolher uma chave, pesquisar, confirmar os dados, digitar o valor que deseja transferir e confirmar a transação.
          </p>
          <p>
            O valor é enviado de imediato para a conta selecionada.
          </p>
        </card>
      </div>


      <div class="col-md-7">
        <card v-if="isNullOrEmpty(transfer.qrCode)" style="height: 100%">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12" v-show="consultSuccess == false">
                  <div class="row">
                    <div class="col-md-12">
                      <div align="center">
                        <div class="btn-group">
                          <p-button class="btn-tab" :class="{'btn-tab-active':key.type == 'document'}" round outline @click="selectType('document')">CPF/CNPJ</p-button>
                          <p-button class="btn-tab" :class="{'btn-tab-active':key.type == 'phoneNumber'}" round outline @click="selectType('phoneNumber')">Celular</p-button>
                          <p-button class="btn-tab" :class="{'btn-tab-active':key.type == 'email'}" round outline @click="selectType('email')">E-mail</p-button>
                          <p-button class="btn-tab" :class="{'btn-tab-active':key.type == 'randomKey'}" round outline @click="selectType('randomKey')">Chave Aleatória</p-button>
                          <p-button class="btn-tab" :class="{'btn-tab-active':key.type == 'paymentCode'}" round outline @click="selectType('paymentCode')">Copia e Cola (Cód. de Pagamento)</p-button>
                          <p-button class="btn-tab" :class="{'btn-tab-active':key.type == 'account'}" round outline @click="selectType('account')">Conta</p-button>
                        </div>
                      </div>
                      <br>
                      <br>
                      <div class="col-md-12" v-show="key.type == ''" align="center">
                        <h4 slot="header" class="card-title">
                          Selecione uma chave para iniciar a transação.
                        </h4>
                      </div>
                      <div class="col-md-12" v-show="key.type == 'document'">
                        <p>Digite o CPF ou CNPJ de quem vai receber:</p>
                        <fg-input type="tel"
                                  v-mask="['###.###.###-##','##.###.###/####-##']"
                                  name="CPF/CNPJ Destino"
                                  v-validate="{cpfCnpjValid:true, required: key.type == 'document', min: 14}"
                                  :error="getError('CPF/CNPJ Destino')"
                                  v-model="key.document">
                        </fg-input>
                      </div>
                      <div class="col-md-12" v-show="key.type == 'phoneNumber'">
                        <p>Digite o número do Celular de quem vai receber:</p>
                        <fg-input type="tel"
                                  v-mask="['(##) ####-####', '(##) #####-####']"
                                  name="Celular"
                                  v-validate="{required: key.type == 'phoneNumber', min: 14}"
                                  :error="getError('Celular')"
                                  v-model="key.phoneNumber">
                        </fg-input>
                      </div>
                      <div class="col-md-12" v-show="key.type == 'email'">
                        <p>Digite o e-mail de quem vai receber:</p>
                        <fg-input v-model="key.email"
                                  v-validate="{required: key.type == 'email', email:true}"
                                  name="email"
                                  :error="getError('email')"
                                  data-vv-as="E-mail">
                        </fg-input>
                      </div>
                      <div class="col-md-12" v-show="key.type == 'randomKey'">
                        <p>Digite a chave aleatória de quem vai receber:</p>
                        <fg-input type="text"
                                  maxlength="36"
                                  name="Chave Aleatória"
                                  v-validate="{required: key.type == 'randomKey'}"
                                  :error="getError('Chave Aleatória')"
                                  v-model="key.randomKey">
                        </fg-input>
                      </div>
                      <div class="col-md-12" v-show="key.type == 'paymentCode'">
                        <p>Digite o código de pagamento:</p>
                        <fg-input type="text"
                                  name="Código de Pagamento"
                                  v-validate="{required: key.type == 'paymentCode'}"
                                  :error="getError('Código de Pagamento')"
                                  v-model="key.paymentCode">
                        </fg-input>
                      </div>
                      <div class="col-md-12" v-show="key.type == 'account'">
                        <p>Preencha os dados da conta:</p>
                        <div class="row">
                          <div class="col-md-12">
                            <label class="control-label">Banco Destino</label>
                            <autocomplete ref="bankAc"
                                          v-validate="{required: key.type == 'account'}"
                                          placeholder="Selecionar"
                                          :source="banksIspb"
                                          input-class="form-control"
                                          results-value="ispb"
                                          :results-display="displayBankName"
                                          @selected="selectBank">
                            </autocomplete>
                          </div>
                        </div>
                        <div class="row" style="margin-top: 10px">
                          <div class="col-md-12">
                            <label class="control-label">Tipo de Conta Destino</label>
                            <el-select size="large" placeholder="Selecionar" style="width: 100%"
                                       v-model="transfer.destinationAccountType">
                              <el-option class="select-success"
                                         value="checking"
                                         label="Corrente"/>
                              <el-option class="select-info"
                                         value="savings"
                                         label="Poupança"/>
                              <el-option class="select-info"
                                         value="salary"
                                         label="Salário"/>
                            </el-select>
                          </div>
                        </div>
                        <div class="row" style="margin-top: 10px">
                          <div class="col-md-12">
                            <label class="control-label">Agência Destino (Sem o Dígito)</label>
                            <fg-input v-model="transferAccount.destinationAgency" style="width: 100%"
                                      v-validate="{required: key.type == 'account'}" type="tel" maxlength="4"
                                      :error="getError('Agência Destino')"
                                      name="Agência Destino" v-mask="['#####']">
                            </fg-input>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-8">
                            <label class="control-label">Conta Destino</label>
                            <fg-input v-model="transferAccount.destinationAccountNumber" style="width: 100%"
                                      v-validate="{required: key.type == 'account'}" type="tel" maxlength="12"
                                      :error="getError('Conta Destino')"
                                      name="Conta Destino" v-mask="['############']">
                            </fg-input>
                          </div>
                          <div class="col-md-4">
                            <label class="control-label">Dígito</label>
                            <fg-input v-model="transferAccount.destinationAccountNumberDv" style="width: 100%"
                                      type="tel" maxlength="1" :error="getError('Dígito')"
                                      name="Dígito" v-mask="['#']">
                            </fg-input>
                          </div>
                        </div>
                        <fg-input type="text"
                                  label="Nome Destino"
                                  maxlength="30"
                                  name="Nome Destino"
                                  v-validate="{required: key.type == 'account'}"
                                  :error="getError('Nome Destino')"
                                  v-model="transfer.destinationAccountOwnerName">
                        </fg-input>

                        <fg-input label="CPF/CNPJ Destino"
                                  type="tel"
                                  v-mask="['###.###.###-##','##.###.###/####-##']"
                                  name="CPF/CNPJ Destino"
                                  v-validate="{cpfCnpjValid:true, required: key.type == 'account', min: 14}"
                                  :error="getError('CPF/CNPJ Destino')"
                                  v-model="transferAccount.destinationAccountOwnerSocialNumber">
                        </fg-input>
                      </div>
                    </div>
                    <div class="col-md-12" style="padding-top: 30px" v-show="key.type != ''">
                      <p-button type="info" @click="consultKey"
                                style="margin: 0 auto; display: table; min-width: 200px; height: 60px">
                        <i slot="label" class="fas fa-search"></i> Consultar
                      </p-button>
                    </div>
                  </div>
                </div>

                <div class="col-md-12" v-show="consultSuccess == true">
                  <div class="row">
                    <div class="col-md-6">
                      <label class="control-label" style="float: left">Valor</label>
                      <money class="form-control input-lg"
                             v-model="value"
                             v-bind="money"
                             :disabled="disableValue">
                      </money>
                      <label class="control-label" style="float: right">
                        Saldo: <b>R$ {{toMoney(accountBalance)}}</b>
                        <br>
                        Saldo disponível para saque: <b>R$ {{toMoney(accountAvailableBalance)}}</b>
                      </label>
                      <label class="control-label" style="float: left">
                        Taxa Serviço: <b>R$ {{serviceFee}}</b>
                      </label>
                    </div>
                    <div class="col-md-6">
                      <label class="control-label" style="float: left">Descrição</label>
                      <fg-input v-model="transfer.userDescription" style="margin: 0 auto" maxlength="20"/>
                    </div>
                  </div>
                  <br><br>
                  <pix-consult-details :transfer="transfer"/>
                  <div class="row">
                    <div class="col-md-6" style="padding-top: 30px">
                      <p-button @click="()=>{consultSuccess = false; clearInterval(validateIntervalLoop)}"
                                type="danger" style="margin: 0 auto; display: table; min-width: 200px; height: 60px">
                        <i class="fas fa-chevron-left"/> Voltar
                      </p-button>
                    </div>
                    <div class="col-md-6" style="padding-top: 30px">
                      <p-button type="info" @click="doTransaction"
                                style="margin: 0 auto; display: table; min-width: 200px; height: 60px">
                        <i slot="label" class="fa fa-compress"></i> Transferir
                      </p-button>
                    </div>
                  </div>
                </div>
              </div>

              <hr v-if="!isNullOrEmpty(transfer.destinationSocialNumber)"/>
              <div class="col-md-12" v-if="!isNullOrEmpty(transfer.destinationSocialNumber)" style="text-align: center">
                <div class="col-sm-12">
                  <label class="control-label">Descrição</label>
                  <fg-input v-model="transfer.userDescription" style="max-width: 200px; margin: 0 auto" maxlength="20"/>
                </div>
              </div>

            </div>
          </div>
        </card>

        <!-- Bloco Dispositivo Não habilitado -->
        <div v-if="!isNullOrEmpty(transfer.qrCode) && transactionAuthorizationTotp==='true' && !isNullOrEmpty(totpQrCodeUri)">
          <card style="background-color: #00d19a; text-align: center">
            <h4 slot="header" class="card-title" style="color: #fff">Seu dispositivo não esta cadastrado!</h4>
            <card align="center" >
              <span>Para liberar suas transações siga os passos abaixo:</span>
              <div class="row"  v-if="isNullOrEmpty(totpQrCodeUri)">
                <i style="margin: 0 auto; margin-top: 14px" class="fas fa-lock fa-5x text-warning"></i>
              </div>

              <div class="row" style="padding: 3%">
                <div class="col-md-12" style="text-align: center" v-if="!isNullOrEmpty(totpQrCodeUri)">
                  <p style="margin-top: 30px"><b>1</b> - Baixe e instale o Aplicativo <span style="color: black"><b style="color: #000000 !important;">SuitPay</b>.</span></p>
                  <p><b>2</b> - Abra o Aplicativo e clique no botão <b>SuitID</b>.</p>
                  <p><b>3</b> - Escaneie o QR Code encontrado no menu: <b>Habilitar Aplicativo</b>.</p>
                  <p><b>4</b> - Pronto, seu aplicativo está habilitado para uso!</p><br>

                  <span>Para download do aplicativo SuitPay acesse:</span><br><br>
                  <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank">
                    <img border="0" alt="Android" src="/static/img/mobile/google_play.png" style="width: 120px">
                  </a>
                  <a href="https://apps.apple.com/br/app/google-authenticator/id388497605" target="_blank">
                    <img border="0" alt="Android" src="/static/img/mobile/apple_store.png" style="width: 120px">
                  </a>

                </div>

                <div class="col-md-12" style="text-align: center" v-if="isNullOrEmpty(totpQrCodeUri)">
                  <p style="margin-top: 14px"><b>1</b> - Abra o aplicativo <span style="color: black; font-weight: bold">Google Authenticator</span></p>
                  <p><b>2</b> - Procure por <b style="color: #000000 !important;">SuitPay</b> e seu nome de usuário no aplicativo </p>
                  <p><b>3</b> - Informe, no campo abaixo, a senha exibida pelo aplicativo </p>
                  <p><b>4</b> - Confirme a operação</p>
                  <p><b>5</b> - Pronto!</p>
                </div>
              </div>
              <br>
              <div style="text-align: center">
                <p-button @click="()=>{transfer.qrCode = ''; clearInterval(validateIntervalLoop)}"
                          class="btn btn-danger" >
                  <i class="fas fa-chevron-left"/> Voltar
                </p-button>
              </div>
            </card>
          </card>
        </div>

        <!-- Bloco Dispositivo Habilitado -->
        <div v-if="!isNullOrEmpty(transfer.qrCode) && transactionAuthorizationTotp==='true' && isNullOrEmpty(totpQrCodeUri)">
          <card style="background-color: #00d19a; text-align: center; height: 100%">
            <h4 slot="header" class="card-title" style="color: #fff">Confirmação de Transação</h4>
            <card align="center" style="height: 100%" >

              <i style="margin: 0 auto; margin-top: 14px" class="fas fa-lock fa-5x text-warning"></i>

              <div class="row" style="padding: 3%">
                <div class="col-md-12" style="text-align: center" v-if="isNullOrEmpty(totpQrCodeUri)">
                  <p style="margin-top: 14px"><b>1</b> - Abra o aplicativo <b>SuitPay</b>.</p>
                  <p><b>2</b> - Selecione a opção <b>SuitId</b>.</p>
                  <p><b>3</b> - Informe, no campo abaixo, o código exibido pelo aplicativo.</p>
                  <p><b>4</b> - Confirme a operação.</p>
                  <p><b>5</b> - Pronto!</p>
                </div>
              </div>

              <span>Informe o <b>SuitID</b>: </span>
              <input style="margin: 0 auto; margin-top: 14px" v-mask="['### ###']" v-model="validationCode" size="6"/>

              <div class="row" v-if="!codeValid">
                <p style="margin: 0 auto; margin-top: 14px" class="text-warning"> Código inválido</p>
              </div>

              <div style="text-align: center">
                <p-button @click="()=>{transfer.qrCode = ''; clearInterval(validateIntervalLoop)}"
                          class="btn btn-danger" >
                  <i class="fas fa-chevron-left"/> Voltar
                </p-button>
                <p-button @click="()=>{validateCode()}"
                          class="btn btn-primary"
                          :disabled="totpConfirmBtnDisabled"
                >
                  <i class="fas fa-chevron-right"/> Confirmar
                </p-button>
              </div>
              <hr/>
              <span>Dados da Transferência</span>
              <pix-consult-details :transfer="transfer"/>
            </card>
          </card>
        </div>
      </div>
    </div>
    <div class="col-md-12" >
      <card>
        <div class="card-body row">
          <div class="col-sm-12" align="center">
            <h4 slot="header" class="card-title">
              Relação de PIX's enviados
            </h4>
            <hr>
          </div>
          <div class="col-sm-12 mt-2">
            <el-table class="table-striped"
                      :data="timelineItems"
                      style="width: 100%">
              <el-table-column :min-width="80" label="Data">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.created}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="80" label="Status">
                <template slot-scope="props">
                    <span>
                      <span>{{statusTranslate(props.row.status)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="Valor">
                <template slot-scope="props">
                    <span>
                      <span>R$ {{toMoney(props.row.value)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="110" label="Nome">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.destinationName}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="110" label="Documento">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.destinationDocument}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="110" label="Banco">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.destinationBank}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :width="60" fixed="right" class-name="td-actions">
                <template slot-scope="props">
                  <p-button type="info" size="sm" icon title="Ver Comprovante"
                            @click="printReceipt(props.row.id)">
                    <i class="fas fa-file-invoice"></i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-success">
            <p class="category">EXIBINDO DO {{paginationItems.fromNumber}} AO {{paginationItems.toNumber}}, DE
              {{paginationItems.totalNumber}} REGISTROS.</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="paginationItems.currentPage"
                          :per-page="paginationItems.perPage"
                          :total="paginationItems.totalNumber"
                          :click="this.loadHistoryPix">
            </p-pagination>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import {isNullOrEmpty, isValidCnpj, isValidCpf, moneyToFloat, notNull, toMoney} from "src/util/core.utils"
  import {failWs, postWs} from 'src/ws.service'
  import swal from 'sweetalert2'
  import nprogress from 'nprogress'
  import Autocomplete from 'vuejs-auto-complete'
  import banksIspb from './banks-ispb'
  import PixTransferDetails from "../../../UIComponents/Dzs/Transfer/PixTransferDetails"
  import PixConsultDetails from "../../../UIComponents/Dzs/Transfer/PixConsultDetails";
  import banksCodes from "../Transfer/banks-codes";
  import Banner from "../../../UIComponents/Promotion/Banner"
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {updateBalance} from "@/util/account.utils";


  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      PixTransferDetails,
      PixConsultDetails,
      Banner,
      PPagination,
      Autocomplete
    },
    data() {
      return {
        serviceFee: '5,00',
        validateIntervalLoop: null,
        accountBalance: '0,00',
        accountAvailableBalance: '0,00',
        value: 0,
        money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: '',
          precision: 2,
          masked: false
        },
        key: {
          type: '',
          document: '',
          phoneNumber: '',
          email: '',
          randomKey: '',
          paymentCode: '',
          consult: ''
        },
        transfer: {
          qrCode: '',
          destinationBankName: '',
          value: 0,
          key: '',
          typeKey: '',
          destinationAccountOwnerName: '',
          destinationAccountOwnerSocialNumber: '',
          destinationAccountOwnerSocialType: '',
          destinationAccountType: '',
          destinationBankIspb: '',
          destinationAgency: '',
          destinationAccountNumber: '',
          userDescription: ''
        },
        transferAccount: {
          destinationAccountNumberDv: '',
          destinationAccountNumber: '',
          destinationAccountOwnerSocialNumber: '',
          destinationAgency: '',
        },
        consultSuccess: false,
        disableValue: false,
        banksIspb: banksIspb,
        transactionAuthorizationEmail: 'false',
        transactionAuthorizationTotp: 'false',
        validationCode: '',
        totpQrCodeUri : '',
        totpManualCode : '',
        totpConfirmBtnDisabled: false,
        codeValid: true,
        timelineItems: [],
        paginationItems: {
          perPage: 5,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
      }
    },
    created() {
      this.getUpdatedBalance()
      this.$validator.extend('cpfCnpjValid', {
        getMessage: field => 'O ' + field + ' é inválido.',
        validate: (value) => {
          value = value.replace(/(\.)|(\/)|(-)/g, '')
          return isValidCnpj(value) || isValidCpf(value)
        }
      })
      this.transactionAuthorizationEmail = notNull(localStorage.getItem('transactionAuthorizationEmail'), 'false')
      this.transactionAuthorizationTotp = notNull(localStorage.getItem('transactionAuthorizationTotp'), 'false')
      this.loadHistoryPix()
    },
    methods: {
      toMoney,
      moneyToFloat,
      isNullOrEmpty,
      doTransaction() {
        let valueAndFee = (this.value + moneyToFloat(this.serviceFee)).toFixed(2)
        if (valueAndFee > this.accountBalance) {
          swal({
            title: 'Aviso!',
            text: 'Você não possui saldo suficiente.',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        this.$validator.validateAll().then(isValid => {
          if (!isValid) {
            return
          }
          this.callPixWs()
        })
      },
      callPixWs() {
        if (this.value <= 0) {
          swal({
            title: 'Aviso!',
            text: 'O valor tem que ser maior que ZERO!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        this.transfer.userDescription = this.transfer.userDescription.toUpperCase()
        this.transfer.destinationAccountOwnerName = this.transfer.destinationAccountOwnerName.toUpperCase()
        this.transfer.value = this.value
        postWs("/pix/request-pix-payment",
          true, null,
          this.transfer,
          (response) => {
            let text = 'Falha ao transferir! Tente mais tarde.'
            if (response.data.response === 'TOTP_CODE_VALIDATION_REQUIRED' && !isNullOrEmpty(response.data.qrCode)) {
              this.transfer.qrCode = response.data.qrCode
              this.totpQrCodeUri = response.data.totpQrCodeUri
              this.totpManualCode = response.data.totpManualCode
              return
            } else if(response.data.response == 'OK'){
              this.success()
              return
            }
            swal({
              title: 'Aviso!',
              text: text,
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          }, (error) => {
            let text = null
            if (error.response.data.response == 'NO_MOBILE_DEVICE_REGISTERED') {
              text = 'Nenhum dispositivo de verificação cadastrado! Para prosseguir, cadastre clicando no menu \'CADASTRAR DISPOSITIVO\'.'
            } else if (error.response.data.response == 'NO_FUNDS') {
              text = 'Você não possui saldo suficiente! Altere o valor e tente novamente.'
            } else if (error.response.data.response == 'ACCOUNT_DOCUMENTS_NOT_VALIDATED') {
              text = 'Conta ainda não verificada! Para prosseguir, favor preencher os dados e enviar a documentação clicando no menu \'VERIFICAR CONTA\'.'
            } else if (error.response.data.response == 'SOURCE_USER_HAVE_NO_ENABLED_ACCOUNT' ||
              error.response.data.response == 'BLOCKED') {
              text = 'Sua conta foi bloqueada! Entre em contato com nosso suporte.'
            } else if (error.response.data.response == 'OPERATION_EXCEEDS_LIMIT') {
              text = 'Esta transação excede os limites para sua conta. Entre em contato com nosso suporte para solicitar um aumento no seu limite.'
            } else if (error.response.data.response == 'SOURCE_USER_INACTIVE') {
              text = 'Sua conta está inativa! Entre em contato com nosso suporte.'
            } else if (error.response.data.response == 'OPERATION_EXCEEDS_TIME_LIMIT') {
              text = 'As solicitações de TED podem ser realizadas de segunda a sexta das 09h à 15h00, tente novamente no horário permitido.'
            } else if (error.response.data.response == 'BLOCKED_OUT_TRANSACTIONS'){
              text = 'As transações de saída desta conta estão em análise!'
            }
            if (!isNullOrEmpty(text)) {
              swal({
                title: 'Aviso!',
                text: text,
                buttonsStyling: true,
                confirmButtonClass: 'btn btn-warning btn-fill',
                type: 'warning'
              })
            } else {
              swal({
                title: 'Serviço temporariamente indisponível!',
                text: 'Tente novamente mais tarde.',
                buttonsStyling: true,
                timer: 5000,
                confirmButtonClass: 'btn btn-danger btn-fill',
                type: 'error'
              })
            }
          })
      },
      transferSuccess(response) {
        if (response.data.status == 'USED') {
          this.success()
        }
      },
      validateCode(){
        this.totpConfirmBtnDisabled = true
        postWs("/verifcode/validate",
          true,
          null,
          {verifCode: this.transfer.qrCode,
            hashCode: this.validationCode},
          this.confirmCode,
          this.failValidateCode)
      },
      failValidateCode() {
        this.totpConfirmBtnDisabled = false
        swal({
          title: 'Aviso!',
          text: 'Código inválido!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      },
      confirmCode(response){
        postWs("/verifcode/confirm",
          true,
          null,
          {verifCode: response.data.verifCode},
          this.success,
          this.failConfirmCode
        )
      },
      success(){
        this.totpConfirmBtnDisabled = false
        clearInterval(this.validateIntervalLoop)
        this.getUpdatedBalance()

        this.consultSuccess = false
        this.transfer.qrCode = ''
        this.transfer.value = 0
        this.transfer.key = ''
        this.transfer.typeKey = ''
        this.transfer.destinationAccountOwnerName = ''
        this.transfer.destinationAccountOwnerSocialNumber = ''
        this.transfer.destinationAccountOwnerSocialType = ''
        this.transfer.destinationAccountType = ''
        this.transfer.destinationBankIspb = ''
        this.transfer.destinationAgency = ''
        this.transfer.destinationAccountNumber = ''
        this.transfer.userDescription = ''
        this.disableValue = false

        this.key.consult = ''
        this.key.document = ''
        this.key.type = ''
        this.key.paymentCode = ''
        this.key.randomKey = ''
        this.key.email = ''
        this.key.phoneNumber = ''
        this.value = 0
        this.validationCode = ''

        this.destinationBankName = ''
        this.loadHistoryPix()
        swal({
          title: 'Sucesso!',
          text: 'Valor transferido com sucesso!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-success btn-fill',
          type: 'success'
        })
        nprogress.done()
      },
      failConfirmCode() {
        this.totpConfirmBtnDisabled = false
        swal({
          title: 'Erro!',
          text: 'Erro ao transferir, tente novamente!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-danger btn-fill',
          type: 'error'
        })
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      selectType(type) {
        this.key.document = ''
        this.key.phoneNumber = ''
        this.key.email = ''
        this.key.randomKey = ''
        this.key.paymentCode = ''
        this.transfer.destinationAccountOwnerName = ''
        this.transfer.destinationAccountOwnerSocialNumber = ''
        this.transfer.destinationAccountOwnerSocialType = ''
        this.transfer.destinationAccountType = ''
        this.transfer.destinationBankIspb = ''
        this.transfer.destinationAgency = ''
        this.transfer.destinationAccountNumber = ''
        this.transferAccount.destinationAccountNumberDv = ''
        this.transferAccount.destinationAccountNumber = ''
        this.transferAccount.destinationAgency = ''
        this.transfer.destinationBankName = ''
        this.$refs.bankAc.display = ''
        this.key.type = type
      },
      consultKey() {
        this.$validator.validateAll().then(isValid => {
          if (!isValid) {
            return
          }
          this.callConsultKey()
        })
      },
      callConsultKey() {
        if(this.key.type == 'account') {
          if (isNullOrEmpty(this.transfer.destinationBankIspb)) {
            swal({
              title: 'Aviso!',
              text: 'O campo Banco Destino é obrigatório.',
              buttonsStyling: true,
              timer: 5000,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            return
          } else if (isNullOrEmpty(this.transfer.destinationAccountType)) {
            swal({
              title: 'Aviso!',
              text: 'O campo Tipo de Conta Destino é obrigatório.',
              buttonsStyling: true,
              timer: 5000,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            return
          }
          this.transfer.key = 'transferToAccount'
          this.transfer.typeKey = 'transferToAccount'
          this.transfer.destinationAccountOwnerSocialNumber = this.transferAccount.destinationAccountOwnerSocialNumber
          this.transfer.destinationAgency = this.transferAccount.destinationAgency
          if(this.transfer.destinationAccountOwnerSocialNumber.replace(/(\.)|(\/)|(-)/g, '').length == 11) {
            this.transfer.destinationAccountOwnerSocialType = 'naturalPerson'
          } else {
            this.transfer.destinationAccountOwnerSocialType = 'legalPerson'
          }
          this.transfer.destinationAccountNumber = this.transferAccount.destinationAccountNumber.concat('-').concat(this.transferAccount.destinationAccountNumberDv)
          this.consultSuccess = true
        } else {
          if (this.key.type == 'document') {
            this.key.consult = this.key.document
          } else if (this.key.type == 'phoneNumber') {
            this.key.consult = this.key.phoneNumber
          } else if (this.key.type == 'email') {
            this.key.consult = this.key.email
          } else if (this.key.type == 'randomKey') {
            this.key.consult = this.key.randomKey
          } else if (this.key.type == 'paymentCode') {
            this.key.consult = this.key.paymentCode.trim()
          }
          postWs("/pix/consult-key",
            true, {key: this.key.consult, keyType: this.key.type},
            null, this.successConsultKey, (error) => this.failConsultKey(error))
        }
      },
      successConsultKey(response) {
        if(response.data.active == false) {
          swal({
            title: 'Aviso!',
            text: 'Chave já foi paga, chave inválida!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-success btn-fill',
            type: 'warning'
          })
        } else {
          this.transfer.key = this.key.consult
          this.transfer.typeKey = response.data.typeKey
          this.transfer.destinationAccountOwnerName = response.data.destinationAccountOwnerName
          this.transfer.destinationAccountOwnerSocialNumber = response.data.destinationAccountOwnerSocialNumber
          this.transfer.destinationAccountOwnerSocialType = response.data.destinationAccountOwnerSocialType
          this.transfer.destinationAccountType = response.data.destinationAccountType
          this.transfer.destinationBankIspb = response.data.destinationBankIspb
          this.transfer.destinationBankName = this.getBank(response.data.destinationBankIspb)
          this.transfer.destinationAgency = response.data.destinationAgency
          this.transfer.destinationAccountNumber = response.data.destinationAccountNumber
          if(response.data.value != 0) {
            this.value = response.data.value
            this.disableValue = true
          }
          this.consultSuccess = true
        }
      },
      failConsultKey(error) {
        if(error.response.status === 509){
          swal({
            title: 'Aviso!',
            text: 'Você atingiu o limite de consultas de chaves PIX para essa conta. Evite realizar consultas sem efetivar a operação. Tente novamente mais tarde!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-success btn-fill',
            type: 'warning'
          })
        } else {
          swal({
            title: 'Aviso!',
            text: 'Dados referente a chave não encontrados ou chave inválida. Verifique a chave e tente novamente!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-success btn-fill',
            type: 'warning'
          })
        }
      },
      getBank(ispb) {
        for (let bank in this.banksIspb) {
          if (this.banksIspb[bank].ispb == ispb) {
            return this.banksIspb[bank].name
          }
        }
        return null
      },
      displayBankName(bank) {
        return bank.ispb + ' - ' + bank.name.toUpperCase()
      },
      selectBank(bank) {
        this.transfer.destinationBankIspb = bank.selectedObject.ispb
        this.transfer.destinationBankName = bank.selectedObject.name
      },
      loadHistoryPix() {
        postWs("/pix/last-requested-pix-payment",
          true, null,
          {
            pageNumber: (this.paginationItems.currentPage - 1),
            pageSize: this.paginationItems.perPage
          }, this.successLoadHistoryPix, this.failLoadHistoryPix)
      },
      successLoadHistoryPix(response) {
        this.timelineItems = response.data.list
        this.paginationItems.totalNumber = response.data.totalNumber
        this.paginationItems.pageMaxNumber = response.data.pageMaxNumber
        if (this.paginationItems.totalNumber > 0) {
          this.paginationItems.fromNumber = ((this.paginationItems.perPage * (this.paginationItems.currentPage - 1)) + 1)
          this.paginationItems.toNumber = ((this.paginationItems.fromNumber + this.timelineItems.length) - 1)
        } else {
          this.paginationItems.fromNumber = 0
          this.paginationItems.toNumber = 0
        }
      },
      failLoadHistoryPix(error) {
        this.timelineItems = []
        return
      },
      statusTranslate(status){
        let retorno = ''
        switch (status) {
          case 'PROCESSED':
            retorno = 'Sucesso';
            break;
          case 'NOT_PROCESSED':
            retorno = 'Não Processado';
            break;
          case 'FAIL':
            retorno = 'Falha';
            break;
          case 'WAITING_PROCESSING':
            retorno = 'Ag. Processamento';
            break;
          case 'CHARGEDBACK':
            retorno = 'Estorno';
            break;
          default:
            retorno = '';
        }
        return retorno
      },
      printReceipt(id) {
        postWs("/pix/get-receipt-pix",
          true,
          {idTransaction: id}, null,
          this.successPrintReceipt,
          (error) => {
            if (error.response.status == 404) {
              swal({
                title: 'Aviso!',
                text: 'Transação não encontrada!',
                buttonsStyling: true,
                confirmButtonClass: 'btn btn-warning btn-fill',
                type: 'warning'
              })
              return;
            }
            swal({
              title: 'Aviso!',
              text: 'Erro ao gerar o comprovante, tente novamente!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            return
          })
      },
      successPrintReceipt(response) {
        try {
          let pdfWindow = window.open("about:blank");
          pdfWindow.document.write("<html<head><title>SuitPay - Comprovante</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>");
          pdfWindow.document.write("<body><embed width='100%' height='100%' src='data:application/pdf;base64," + encodeURI(response.data.pdfBase64) + "'></embed></body></html>");
        } catch (ex) {
          swal({
            title: 'Aviso!',
            text: "Para a exibição do comprovante, favor permitir janela pop-up no navegador. Após permitido favor tentar novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      getUpdatedBalance() {
        updateBalance().then(() => {
          this.accountBalance = moneyToFloat(notNull(localStorage.getItem('accountBalance'), '0,00'))
          this.accountAvailableBalance = moneyToFloat(notNull(localStorage.getItem('accountAvailableBalance'), '0,00'))
        })
      }
    }
  }
</script>
<style lang="scss">
  .dzs-ted {
    .autocomplete__icon {
      display: none;
    }

    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }

    .picture-row {
      margin-top: 20px;
      background-color: #5e5e5e;
    }

    .picture-container {
      margin: 5px auto;
      display: table;
    }

    .picture {
      float: left;
      width: 80px;
      height: 80px;
      background-color: #999;
      border: 1px solid #e3e3e3;
      color: #fff;
      border-radius: 50%;
      overflow: hidden;
      transition: all .2s;
      -webkit-transition: all .2s;
    }

    .picture-text {
      float: right;
      margin-top: 20px;
      margin-left: 6px;
    }

    .clickable-rows {
      tbody tr td {
        cursor: pointer;
      }

      .el-table__expanded-cell {
        cursor: default;
      }
    }

    .btn-tab {
      border-color: #0C072D !important;
      color: #0C072D !important;
      padding-right: 15px;
      padding-left: 15px;
    }

    .btn-tab:hover {
      background-color: #0C072D !important;
      border-color: #0C072D !important;
      color: #FFFFFF !important;
    }

    .btn-tab:focus {
      background-color: #0C072D !important;
      border-color: #0C072D !important;
      color: #FFFFFF !important;
    }

    .btn-tab:active:focus {
      background-color: #0C072D !important;
      border-color: #0C072D !important;
      color: #FFFFFF !important;
    }

    .btn-tab-active {
      background-color: #0C072D !important;
      border-color: #0C072D !important;
      color: #FFFFFF !important;
    }
  }
</style>
