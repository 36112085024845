<template>
  <div class="suit-journey"
       style="background: url(/static/img/suitjourney/bgTest.png), #000;background-size: contain;background-position: center bottom;background-repeat: no-repeat;">
    <section class="hero">
      <div class="row">
        <div class="col-lg-4 hero__content">
          <img src="/static/img/login/carousel/logos/SUIT_JOURNEY.png" alt="">
          <div>
            <p>UMA JORNADA DE FATURAMENTO</p>
            <p>FORA DA REALIDADE</p>
            <p>Sua realidade nunca mais será a mesma. <b>Chegou a hora de conquistar níveis inimagináveis de
              faturamento.</b></p>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="revenue">
            <div ref="currentRevenueRef" class="revenue__card">
              <div class="revenue__card__header">
                <p>NÍVEL DE FATURAMENTO ATUAL</p>
                <small>R$ {{ toMoney(currentRevenue) }}</small>
              </div>
              <div class="revenue__progress-bar-wrapper">
                <div class="revenue__progress-bar__current">
                  <div class="img-container">
                    <img :src="currentPlateIcon" />
                  </div>
                  <p>R$ {{ toMoney(currentPlateRevenue) }}</p>
                </div>
                <div class="revenue__progress-bar">
                  <div ref="revenueProgressBarValue" class="revenue__progress-bar--value"></div>
                </div>
                <div class="revenue__progress-bar__next">
                  <div class="img-container">
                    <img :src="nextPlateIcon" />
                  </div>
                  <p>R$ {{ toMoney(nextPlateRevenue) }}</p>
                </div>
              </div>
<!--              <div class="revenue__card__footer">-->
<!--                <p>Continue aumentando seu faturamento pela SuitPay para acessar seu próximo desafio.</p>-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </div>
      <div class="start-here row">
        <div class="col-lg-12">
          <div class="start-here__content">
            <iframe
              src="https://player.vimeo.com/video/942378165?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"></iframe>
          </div>
        </div>
      </div>
    </section>
    <main class="container-fluid">
      <section class="map-suit-journey row">
        <div class="col-lg-12">
          <div class="map-suit-journey__text">
            <h2><span class="map__text--highlight">MAPA DO FATURAMENTO</span></h2>
            <p>Descubra qual é o seu desafio atual clicando nos ícones dentro do Mapa do Faturamento. Leia a carta com atenção para resgatar sua recompensa.</p>
          </div>
          <div class="svg-container"
               style="background-image: url(/static/img/suitjourney/grid.png); background-size: cover; background-position: center">
            <SuitJourneyMap ref="suitJourneyMapRef" @stageClicked="showLetter" :current-plate="currentPlateIndex"/>
          </div>
        </div>
      </section>
      <section ref="addressFormRef" class="address row">
        <div class="col-lg-12">
          <div class="address__text">
            <h2>DADOS DE ENTREGA</h2>
            <p>Para ganhar sua recompensa, preencha com muita atenção os campos abaixo com o endereço exato em que
              você deseja receber sua premiação.</p>
          </div>
          <div v-if="false" class="address__cta">
            MUDAR ENDEREÇO
          </div>
          <form v-else class="address__form" @submit.prevent="sendPlateRequest">
            <div class="row">
              <div class="col-lg-12">
                <p-checkbox class="text-left" v-model="formPlateRequest.mainAddressSameShippingAddress">
                  Utilizar o endereço de cadastro como endereço de entrega.
                </p-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-7">
                <fg-input type="tel"
                          v-mask="['#####-###']"
                          label="CEP"
                          name="CEP"
                          v-validate="formValidation.cep"
                          :error="getError('CEP')"
                          @blur="this.searchZipCode"
                          v-model="formPlateRequest.deliveryAddress.zipCode"
                          key="cep"
                          :disabled="formPlateRequest.mainAddressSameShippingAddress">
                </fg-input>
              </div>
              <div class="col-lg-5">
                <fg-input type="text"
                          label="Rua"
                          id="street"
                          name="street"
                          v-validate="formValidation.street"
                          data-vv-as="Rua"
                          :error="getError('street')"
                          v-model="formPlateRequest.deliveryAddress.street"
                          :disabled="formPlateRequest.mainAddressSameShippingAddress">
                </fg-input>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4">
                <fg-input type="text"
                          label="Cidade"
                          name="city"
                          v-validate="formValidation.city"
                          data-vv-as="Cidade"
                          :error="getError('city')"
                          v-model="formPlateRequest.deliveryAddress.city"
                          :disabled="formPlateRequest.mainAddressSameShippingAddress">
                </fg-input>
              </div>
              <div class="col-lg-3">
                <fg-input type="text"
                          label="Estado"
                          name="state"
                          v-validate="formValidation.state"
                          data-vv-as="Estado"
                          :error="getError('state')"
                          v-model="formPlateRequest.deliveryAddress.state"
                          :disabled="formPlateRequest.mainAddressSameShippingAddress">
                </fg-input>
              </div>
              <div class="col-lg-2">
                <fg-input type="tel"
                          v-mask="['######']"
                          label="Número"
                          name="number"
                          v-validate="formValidation.number"
                          data-vv-as="Número"
                          :error="getError('number')"
                          v-model="formPlateRequest.deliveryAddress.number"
                          :disabled="formPlateRequest.mainAddressSameShippingAddress">
                </fg-input>
              </div>
              <div class="col-lg-3">
                <fg-input type="text"
                          label="Bairro"
                          name="neighborhood"
                          v-validate="formValidation.neighborhood"
                          data-vv-as="Bairro"
                          :error="getError('neighborhood')"
                          v-model="formPlateRequest.deliveryAddress.neighborhood"
                          :disabled="formPlateRequest.mainAddressSameShippingAddress">
                </fg-input>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-7">
                <fg-input type="text"
                          maxlength="40"
                          label="Complemento"
                          name="complement"
                          v-validate="formValidation.complement"
                          data-vv-as="Complemento"
                          :error="getError('complement')"
                          v-model="formPlateRequest.deliveryAddress.complement"
                          key="complemento"
                          :disabled="formPlateRequest.mainAddressSameShippingAddress">
                </fg-input>
              </div>
              <div class="col-lg-2">
                <fg-input type="text"
                          label="Whatsapp"
                          id="phoneNumber"
                          name="phoneNumber"
                          v-mask="['(##) ####-####', '(##) #####-####']"
                          v-validate="formValidation.phoneNumber"
                          data-vv-as="Whatsapp"
                          :error="getError('phoneNumber')"
                          v-model="formPlateRequest.phoneNumber">
                </fg-input>
              </div>
              <div class="col-lg-3 d-flex align-items-end">
                <button type="submit" class="address__form__submit" :disabled="!hasPlatesToRequest">RECEBER RECOMPENSA
                </button>
              </div>
            </div>
            <footer class="address__form__footer">
              <p>Em breve você receberá uma confirmação de envio e rastreio<br>pelo seu número de Whatsapp, fique
                atento(a).</p>
            </footer>
          </form>
        </div>
      </section>
    </main>
    <transition name="fade" mode="out-in">
      <div v-if="isShowingLetter" ref="letterWrapperRef" @click="handleLetterClick" class="letter-wrapper">
        <component @ctaClicked="handleLetterCtaClick" :is="letterContentComponent"
                   :hasPlatesToRequest="hasPlatesToRequest"></component>
      </div>
    </transition>
    <transition name="slide-in" mode="in-out">
      <suit-journey-notification v-if="isShowingNotification"  @click="handleNotificationClick" :item-name="currentNotificationName" />
    </transition>
  </div>
</template>

<script>
import {getWs, postWs} from "@/ws.service";
import swal from "sweetalert2";
import SuitJourneyMap from "@/components/Dashboard/Views/SuitJourney/Icons/SuitJourneyMap";
import SuitJourneyNotification from "@/components/Dashboard/Views/SuitJourney/Notifications/SuitJourneyNotification.vue";
import {isNotSomeAuthorization} from "@/util/role.utils";
import {router} from "@/main";
import {toMoney} from "@/util/core.utils";

export default {
  name: "SuitJourney",
  components: {
    SuitJourneyMap,
    SuitJourneyNotification,
    GreenGateLetter: () => import('@/components/Dashboard/Views/SuitJourney/Letters/GreenGateLetter.vue'),
    BlackSuitLetter: () => import('@/components/Dashboard/Views/SuitJourney/Letters/BlackSuitLetter.vue'),
    TheOracleLetter: () => import('@/components/Dashboard/Views/SuitJourney/Letters/TheOracleLetter.vue'),
    TheLabyrinthLetter: () => import('@/components/Dashboard/Views/SuitJourney/Letters/TheLabyrinthLetter.vue'),
    TheAbyssLetter: () => import('@/components/Dashboard/Views/SuitJourney/Letters/TheAbyssLetter.vue'),
    TheWayLetter: () => import('@/components/Dashboard/Views/SuitJourney/Letters/TheWayLetter.vue'),
    BlackOceanLetter: () => import('@/components/Dashboard/Views/SuitJourney/Letters/BlackOceanLetter.vue'),
    SuitSourceLetter: () => import('@/components/Dashboard/Views/SuitJourney/Letters/SuitSourceLetter.vue'),
    TheLibertyLetter: () => import('@/components/Dashboard/Views/SuitJourney/Letters/TheLibertyLetter.vue'),
  },
  data() {
    return {
      viacep: '',
      formPlateRequest: {
        mainAddressSameShippingAddress: true,
        deliveryAddress: {
          zipCode: '',
          codIbge: '',
          cep: '',
          street: '',
          city: '',
          state: '',
          number: '',
          neighborhood: '',
          complement: '',
        },
        phoneNumber: null
      },
      plates: [
        {
          name: 'GREEN_GATE',
          icon: 'greenGate.png',
          revenue: 0
        },
        {
          name: 'BLACK_SUIT',
          icon: 'blackSuit.png',
          revenue: 1000
        },
        {
          name: 'ORACLE',
          icon: 'theOracle.png',
          revenue: 100000
        },
        {
          name: 'LABYRINTH',
          icon: 'theLabyrinth.png',
          revenue: 250000
        },
        {
          name: 'ABYSS',
          icon: 'theAbyss.png',
          revenue: 500000
        },
        {
          name: 'THE_WAY',
          icon: 'theWay.png',
          revenue: 1000000
        },
        {
          name: 'BLACK_OCEAN',
          icon: 'blackOcean.png',
          revenue: 5000000
        },
        {
          name: 'SOURCE',
          icon: 'suitSource.png',
          revenue: 10000000
        },
        {
          name: 'THE_LIBERTY',
          icon: 'theLiberty.png',
          revenue: 20000000
        }
      ],
      currentRevenue: 0,
      hasPlatesToRequest: false,
      isShowingLetter: false,
      isShowingNotification: false,
      currentNotificationName: '',
      letterContentComponent: ''
    }
  },
  watch: {
    'formPlateRequest.mainAddressSameShippingAddress'(value) {
      if (!value) {
        this.clearDeliveryAddressFields()
      } else {
        this.getMainAddress()
      }
    },
  },
  beforeCreate() {
    if (isNotSomeAuthorization(['SUIT_JOURNEY'])) {
      router.push('/suitjourney/prelaunch')
    }
  },
  mounted() {
    this.getMainAddress()
    this.getRevenue()
    this.checkIfUserHasLetterToRead()
  },
  computed: {
    currentPlateIcon() {
      const image = new Image()
      const plateIndex = this.currentPlateIndex < this.plates.length ? this.currentPlateIndex : this.currentPlateIndex - 1
      image.src = `/static/img/suitjourney/icons/${this.plates[plateIndex - 1].icon}`
      return image.src
    },
    currentPlateRevenue() {
      const plateIndex = this.currentPlateIndex < this.plates.length ? this.currentPlateIndex : this.currentPlateIndex - 1
      return this.plates[plateIndex - 1].revenue
    },
    nextPlateIcon() {
      const image = new Image()

      if (this.currentPlateIndex - 1 < this.plates.length - 1) {
        image.src = `/static/img/suitjourney/icons/${this.plates[this.currentPlateIndex].icon}`
      } else {
        image.src = `/static/img/suitjourney/icons/${this.plates[this.currentPlateIndex - 1].icon}`
      }

      return image.src
    },
    nextPlateRevenue() {
      if (this.currentPlateIndex - 1 < this.plates.length - 1) {
        return this.plates[this.currentPlateIndex].revenue
      }
      return this.plates[this.currentPlateIndex - 1].revenue
    },
    currentPlateIndex() {
      let currentPlateIndex = 0
      this.plates.forEach((plate, index) => {
        if (this.currentRevenue >= plate.revenue && index < this.plates.length) {
          currentPlateIndex = index
        }
      })
      return localStorage.getItem('docsOk') !== 'false' ? currentPlateIndex + 1 : 0
    },
    formValidation() {
      return {
        cep: {
          required: true,
          min: 9
        },
        street: {
          required: true
        },
        city: {
          required: true
        },
        state: {
          required: true
        },
        number: {
          required: !this.formPlateRequest.mainAddressSameShippingAddress
        },
        neighborhood: {
          required: true
        },
        complement: {
          required: !this.formPlateRequest.mainAddressSameShippingAddress
        },
        phoneNumber: {
          required: true
        },
      }
    }
  },
  methods: {
    toMoney,
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    searchZipCode() {
      this.viacep = null
      this.formPlateRequest.deliveryAddress.codIbge = ""
      this.formPlateRequest.deliveryAddress.city = ""
      this.formPlateRequest.deliveryAddress.state = ""
      getWs("/cep/get-cep",
        true, {cep: this.formPlateRequest.deliveryAddress.zipCode},
        this.successSearchZipCode,
        this.failSearchZipCode)
    },
    successSearchZipCode(response) {
      this.viacep = response.data
      this.formPlateRequest.deliveryAddress.codIbge = this.viacep.ibge
      if (this.viacep.logradouro.length > 40) {
        this.formPlateRequest.deliveryAddress.street = this.viacep.logradouro.substr(0, 40)
      } else {
        this.formPlateRequest.deliveryAddress.street = this.viacep.logradouro
      }
      if (this.viacep.bairro.length > 40) {
        this.formPlateRequest.deliveryAddress.neighborhood = this.viacep.bairro.substr(0, 40)
      } else {
        this.formPlateRequest.deliveryAddress.neighborhood = this.viacep.bairro
      }
      this.formPlateRequest.deliveryAddress.city = this.viacep.localidade
      this.formPlateRequest.deliveryAddress.state = this.viacep.uf
    },
    failSearchZipCode() {
      swal({
        title: 'Aviso!',
        text: 'CEP não encontrado nos Correios, favor verifique os dados e tente novamente!',
        buttonsStyling: true,
        timer: 10000,
        confirmButtonClass: 'btn btn-danger btn-fill',
        type: 'warning'
      })
      return
    },
    sendPlateRequest() {
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          return
        }
        postWs('/suitjourney/plate/request', true, null,
          this.formPlateRequest,
          this.successSendPlateRequest,
          this.failSendPlateRequest)
      })
    },
    successSendPlateRequest(response) {
      const messages = {
        200: {
          title: 'Solicitação realizada com sucesso!',
          content: 'Em breve você receberá uma confirmação de envio e rastreio pelo seu número de Whatsapp, fique atento(a).'
        },
        204: {
          title: 'Atenção!',
          content: 'Você não possui nenhuma placa a ser solicitada!'
        }
      }

      swal({
        title: messages[response.status].title,
        text: messages[response.status].content,
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        type: 'success'
      })

      let requestedItems = JSON.parse(localStorage.getItem('suitJourneyAvailableToRequest')).filter(item => item.name.endsWith('Letter'))
      localStorage.setItem('suitJourneyAvailableToRequest', JSON.stringify(requestedItems))

      this.$refs.suitJourneyMapRef.getAvailableItemsFromLocalStorage()
    },
    failSendPlateRequest(error) {
      swal({
        title: 'Atenção!',
        text: 'Ocorreu um erro ao realizar sua solicitação. Tente novamente.',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      })
    },
    getMainAddress() {
      getWs('/suitjourney/main-address', true, null,
        this.successGetMainAddress,
        this.failGetMainAddress
      )
    },
    successGetMainAddress(response) {
      this.formPlateRequest.deliveryAddress.zipCode = response.data.zipCode
      this.formPlateRequest.deliveryAddress.street = response.data.street
      this.formPlateRequest.deliveryAddress.city = response.data.city
      this.formPlateRequest.deliveryAddress.state = response.data.state
      this.formPlateRequest.deliveryAddress.number = response.data.number
      this.formPlateRequest.deliveryAddress.neighborhood = response.data.neighborhood
      this.formPlateRequest.deliveryAddress.complement = response.data.complement
    },
    failGetMainAddress(error) {
      console.warn(error)
    },
    getRevenue() {
      getWs('/suitjourney/revenue', true, null,
        this.successGetRevenue,
        this.failGetRevenue
      )
    },
    successGetRevenue(response) {
      this.currentRevenue = response.data.revenue
      this.hasPlatesToRequest = response.data.hasPlatesToRequest
      this.setCurrentRevenueBarWidth()
    },
    failGetRevenue(error) {
      console.log('Ocorreu um erro ao buscar faturamento: ', error)
    },
    clearDeliveryAddressFields() {
      this.formPlateRequest.deliveryAddress.zipCode = ''
      this.formPlateRequest.deliveryAddress.street = ''
      this.formPlateRequest.deliveryAddress.city = ''
      this.formPlateRequest.deliveryAddress.state = ''
      this.formPlateRequest.deliveryAddress.number = ''
      this.formPlateRequest.deliveryAddress.neighborhood = ''
      this.formPlateRequest.deliveryAddress.complement = ''
      this.formPlateRequest.phoneNumber = null
    },
    showLetter(stage) {
      this.isShowingLetter = true
      this.letterContentComponent = stage.concat('Letter')
      this.setLetterAsRead(stage.concat('Letter'))
      window.addEventListener('keydown', this.handleLetterClick)
    },
    hideLetter() {
      this.isShowingLetter = false
      this.isShowingNotification = false
      window.removeEventListener('keydown', this.handleLetterClick)
    },
    handleLetterClick(event) {
      if (event.type === 'click' && event.target === this.$refs.letterWrapperRef) {
        this.hideLetter()
      } else if (event.key === 'Escape' || event.key === 'Enter') {
        this.hideLetter()
      }
    },
    handleLetterCtaClick() {
      this.hideLetter()
      this.$refs.addressFormRef.scrollIntoView({behavior: 'smooth'});
    },
    handleTrailerPopUpClick(event) {
      if (event.target === this.$refs.trailerWrapper) {
        this.hideTrailerPopUp()
      } else if (event.key === 'Escape' || event.key === 'Enter') {
        this.hideTrailerPopUp()
      }
    },
    setCurrentRevenueBarWidth() {
      let currentPlateIndex = 0
      this.plates.forEach((plate, index) => {
        if (this.currentRevenue >= plate.revenue) {
          currentPlateIndex = index
        }
      })

      if (currentPlateIndex + 1 === this.plates.length) {
        this.$refs.revenueProgressBarValue.style.width = '100%'
      } else {
        this.$refs.revenueProgressBarValue.style.width = `${(this.currentRevenue * 100) / this.plates[currentPlateIndex + 1].revenue}%`
      }
    },
    checkIfUserHasLetterToRead() {
      const suitJourneyItems = JSON.parse(localStorage.getItem('suitJourneyAvailableToRequest'))
      const regex = new RegExp('Letter$')
      const lettersToRead = suitJourneyItems.filter(item => regex.test(item.name))
      if (lettersToRead.length > 0) {
        if (this.$route.query.showLetter) {
          this.showLetter(lettersToRead[0].name.replace(/Letter$/i, ''))
        } else {
          this.showNotification(lettersToRead[0].name)
        }
      }
    },
    setLetterAsRead(letter) {
      postWs('/suitjourney/activation/activate', true, null,
        {
          activationIdentifier: letter
        },
        () => this.successSetLetterAsRead(letter), () => null
      )
    },
    successSetLetterAsRead(letterIdentifier) {
      const suitJourneyItems = JSON.parse(localStorage.getItem('suitJourneyAvailableToRequest'))
      const updatedSuitJourneyItems = suitJourneyItems.filter(item => item.name !== letterIdentifier)
      localStorage.setItem('suitJourneyAvailableToRequest', JSON.stringify(updatedSuitJourneyItems))
      this.$refs.suitJourneyMapRef.getAvailableItemsFromLocalStorage()
    },
    handleNotificationClick(itemName) {
      this.showLetter(itemName.replace('Letter', ''))
      setTimeout(() => {
        this.isShowingNotification = false
      }, 2000)
    },
    showNotification(itemName) {
      this.currentNotificationName = itemName
      this.isShowingNotification = true
    }
  }
}
</script>

<style lang="scss">
.suit-journey {
  overflow: hidden;
  background-position: center bottom;
  background-repeat: no-repeat;

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .3s
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0
  }

  .slide-in-enter-active,
  .slide-in-leave-active {
    transition: all .6s ease-in-out;
  }

  .slide-in-enter,
  .slide-in-leave-to {
    transform: translateX(2rem);
    opacity: 0;
  }
}

main {
  max-width: 80rem;
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem !important;

  section {
    z-index: 10;
  }
}

.hero {
  padding: 2rem 0 2rem 0;
  align-items: center;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 1) 100%), url(~/public/static/img/login/carousel/background/SUITJOURNEY.webp);
  background-size: cover;

  .row {
    width: 100%;
    max-width: 80rem;
    margin: 0 auto;
    padding-inline: 15px;
  }
}

.hero__content {
  color: #FFFFFF;
  font-family: ArticulatCF, sans-serif;
  text-transform: none;
  text-align: center;
  padding: 0 0 3rem 0;

  img {
    width: 5.525rem;
    margin: 0 0 2rem 0;
  }

  h3 {
    color: #33CC33;
  }

  p {
    margin: 0 !important;
    line-height: 1.125;
  }

  > div p:nth-child(1) {
    font-size: 1.2rem;
    font-weight: bold;
  }

  > div p:nth-child(2) {
    font-size: 4.8rem;
    font-family: Mongoose, sans-serif;
    color: #33CC33;
  }

  > div p:nth-child(3) {
    font-size: 1rem;
    width: 100%;
    max-width: 26.25rem;
    margin-inline: auto !important;
  }
}

.start-here {
  padding: 1rem 0 4.5rem 0;

  .start-here__content {
    position: relative;
    box-shadow: 0 0 32px #33CC33;
    width: 100%;
    max-width: 60rem;
    margin-inline: auto;
    aspect-ratio: 16/9;
    border-radius: 2.25rem;
    background: #000;
    font-weight: bold;
    color: #FFF;
    font-size: 4rem;
    font-family: Mongoose, sans-serif;
    overflow: hidden;

    iframe {
      border: none;
      width: 100%;
      height: 100%;
      background: #000;
    }
  }
}

.revenue {
  .revenue__card {
    padding: 1rem 1.5rem;
    background: #101010;
    width: 100%;
    border-radius: 2.875rem;
    color: #FFF;
    text-align: center;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    align-items: center;

    .revenue__card__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > p {
        font-family: Mongoose, sans-serif;
        font-size: 2rem;
        font-weight: bold;
        margin: 0;
      }

      > small {
        font-family: ArticulatCF, sans-serif;
        font-size: 1.25rem;
        font-weight: bold;
        color: #33CC33;
      }
    }

    .revenue__card__footer {
      font-size: .9rem;
      p {
        margin: 0;
      }
    }
  }

  .revenue__progress-bar-wrapper {
    display: flex;
    align-items: center;

    .revenue__progress-bar {
      margin-inline: -4.5rem;
    }
  }

  .revenue__progress-bar__current, .revenue__progress-bar__next {
    display: flex;
    gap: .5rem;
    flex-direction: column;
    font-family: ArticulatCF, sans-serif;
    font-weight: bold;
    font-size: 1rem;
    z-index: 20;

    .img-container {
      height: 4rem;
      width: 8.25rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        align-self: center;
        margin-bottom: .5rem;
      }
    }

    p {
      white-space: nowrap;
      margin: 0;
    }
  }

  .revenue__progress-bar {
    margin-inline: -3rem;
    z-index: 10;
    width: 100%;
    height: 1.25rem;
    border-radius: 0.625rem;
    background: transparent;
    border: 4px solid #FFF;
    position: relative;
    overflow: hidden;

    .revenue__progress-bar--value {
      background: #33cc33;
      border-radius: 0.625rem;
      height: 100%;
      width: 0;
      transition: 2s ease-in-out width;
    }
  }
}

.map-suit-journey {
  .map-suit-journey__text {
    text-align: center;
    color: #FFF;

    h2 {
      font-family: Mongoose, sans-serif;
      font-weight: 900;
      font-size: 2.2rem;
    }

    p {
      font-size: 1rem;
      width: 100%;
      max-width: 30rem;
      margin-inline: auto;
    }

    .map__text--highlight {
      color: #33cc33;
    }
  }

  .svg-container {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    max-height: 48rem;
  }

  svg {
    width: 95%;
    height: 95%;
  }
}

.address {
  padding: 8rem 0;

  .address__text {
    text-align: center;
    color: #FFF;

    h2 {
      font-weight: bold;
      font-size: 3.725rem;
      font-family: Mongoose, sans-serif;
    }

    p {
      font-size: 1rem;
    }
  }

  .address__cta {
    padding: 1.5rem 3rem;
    border-radius: 3rem;
    background: #33CC33;
    color: white;
    font-size: 1.25rem;
    font-weight: bold;
    width: fit-content;
    margin: 0 auto;
  }

  .address__form {
    background: #101010;
    border-radius: 3rem;
    padding: 1.5rem 2rem;
    font-family: ArticulatCF, sans-serif !important;
    color: #FDFDFD;

    .row .form-control {
      font-family: ArticulatCF, sans-serif !important;
      background: #2F2F2F !important;
      border-radius: .75rem !important;
      padding: .5rem .75rem !important;
      border: none;
    }

    .address__form__submit {
      width: 100%;
      padding: .5rem 1.5rem;
      margin-bottom: 10px;
      background: #33CC33;
      outline: none;
      border: none;
      border-radius: 3rem;
      align-self: end;
      font-family: ArticulatCF, sans-serif;
      font-weight: bold;
      color: #FFF;
      cursor: pointer;
      transition: opacity .3s ease;
    }

    .address__form__submit:hover, .address__form__submit:disabled {
      opacity: .8;
    }

    .address__form__submit:disabled {
      cursor: initial;
    }
  }
}

.trailer-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: grid;
  place-content: center;
  z-index: 1000;
  backdrop-filter: blur(.5rem);
  background: rgba(0, 0, 0, .7);

  iframe {
    border: none;
    position: absolute;
    top: 50%;
    left: 50%;
    aspect-ratio: 16/9;
    width: 90%;
    max-height: 80%;
    max-width: 80rem;
    transform: translate(-50%, -50%);
    background: #000;
    border-radius: 1rem;
    box-shadow: 0px 0px 32px rgba(51, 204, 51, .75);
  }
}

.letter-wrapper {
  padding: 3rem 1rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: grid;
  justify-content: center;
  z-index: 999;
  backdrop-filter: blur(.5rem);
  background: rgba(255, 255, 255, .5);
  overflow-y: auto;

  .letter__close {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .letter {
    width: 100%;
    max-width: 45rem;
    max-height: 90vh;
    background: rgba(0, 0, 0, .90);
    backdrop-filter: blur(2rem);
    border-radius: 1rem;
    color: white;
    overflow: auto;
    opacity: 0;
    animation: slide-in-up .6s ease-in-out 0.3s forwards;
  }

  .letter::-webkit-scrollbar {
    width: .5rem;
  }

  .letter::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 1rem;
    border: 2px solid transparent;
  }

  .letter::-webkit-scrollbar-thumb {
    background: #33CC33;
    border-radius: 1rem;
  }

  .letter__image {
    height: 100%;
    max-height: 20rem;
    border-bottom: 4px solid #33CC33;

    img {
      border-radius: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .letter__header {
    text-align: center;
    font-family: Mongoose, sans-serif;
    font-size: 3rem;
    color: #33CC33;
    padding: 2rem 0 0 0;

    h2 {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: .5rem;
    }
  }

  .letter__body {
    font-size: 1.2rem;
    padding: 2rem 2.5rem;

    .speech {
      padding-left: 2rem;
    }

    .speech-bold {
      font-weight: bold;
    }

    .speech-serif {
      font-style: italic;
      font-family: serif;
    }

    b, u {
      color: #33CC33;
    }

    blockquote {
      padding: 1rem 1.5rem;
      border-radius: 1rem;
      border: 1px solid #33CC33;
      background: rgba(51, 204, 51, .25);
    }
  }

  hr {
    height: 1px;
    background-color: #FFF;
    width: 100%;
    max-width: 14rem;
    margin: 2rem auto;
  }

  .letter__footer {
    text-align: center;
    font-size: 1.225rem;
    padding: 2rem 2.5rem;

    .letter__footer__cta {
      padding: .5rem 1.5rem;
      margin-bottom: 10px;
      background: #33CC33;
      outline: none;
      border: none;
      border-radius: 3rem;
      align-self: end;
      font-family: ArticulatCF, sans-serif;
      font-weight: bold;
      color: #FFF;
      cursor: pointer;
      transition: opacity .3s ease;
    }
  }
}

@media screen and (min-width: 992px) {
  main {
    padding: 0 2rem !important;
  }
  .hero__content {
    padding: 0 0 3rem 0;
    img {
      width: 3rem;
      margin: 0 0 .5rem 0;
    }
    > div p:nth-child(1) {
      font-size: .9rem;
      font-weight: bold;
    }

    > div p:nth-child(2) {
      font-size: 3rem;
      font-weight: bold;
      color: #33CC33;
      line-height: 1;
    }

    > div p:nth-child(3) {
      font-size: .8rem;
      padding: 0 15%;
    }
  }

  .revenue {
    .revenue__card {
      padding: 1.5rem 2rem;
      font-size: 1.5rem;

      > p {
        font-size: 2rem;
        font-weight: bold;
        margin: 0;
      }

      > small {
        font-size: 1.5rem;
      }
    }
  }

  .start-here .start-here__content {
    font-size: 3.85rem;
  }

  .map-suit-journey {
    .map-suit-journey__text {
      h2 {
        font-size: 5rem;
        padding: 0 10%;
      }

      p {
        font-size: 1.05rem;
      }
    }
  }

  .address__text {
    h2 {
      font-size: 4.725rem !important;
    }

    p {
      padding: 0 20%;
    }
  }
}

@keyframes slide-in-up {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
